import React from 'react'
import { Link } from "react-router-dom";
import { collect } from 'react-recollect'
import { appStore } from '../store/store';
import { statusesMap } from '../components/PatientsTable';
import { getLangType } from '../utils/utils';
import { languageOptions } from '../language';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import PatientDetails from './PatientDetails'
import { Tooltip } from 'react-tooltip';

const langType = getLangType();

function PatientDetailsModal({ store, activeTab }) {

  return (
    <>
      {Object.keys(store.selectedRow).length > 1 ? (
        <Modal isOpen={true} toggle={appStore.closeDetails} size='xl'>
          <ModalHeader toggle={appStore.closeDetails}>
            {store.selectedRow.first_name} {store.selectedRow.last_name}
            &nbsp;&nbsp;<span style={{ color: 'dodgerblue' }}>|</span>&nbsp;&nbsp;
            {store.selectedRow.patientId}
            &nbsp;&nbsp;<span style={{ color: 'dodgerblue' }}>|</span>&nbsp;&nbsp;
            {languageOptions[statusesMap[store.selectedRow.status]][langType]}
            <span>
              <Tooltip id='printToPdfToolTip' place='bottom' />
              <span data-tooltip-id='printToPdfToolTip' data-tooltip-content={languageOptions.printToPDF[langType]}>
                <Link to='/print/patient' target='_blank' rel='noopener noreferrer'>
                  <i className='fas fa-print' style={{ width: '28px', marginLeft: '7px' }}></i>
                </Link>
              </span>
            </span>
          </ModalHeader>
          <ModalBody>
            <PatientDetails close={appStore.closeDetails} activeTab={activeTab || "masks"} />
          </ModalBody>
          <ModalFooter
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button color='secondary' onClick={appStore.closeDetails}>
              {languageOptions.close[langType]}
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  )
}

export default collect(PatientDetailsModal)
