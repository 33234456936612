import React from 'react'
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap'
import { getMaskDisplayName } from '../utils/utils'
import { collect } from 'react-recollect'
import { appStore } from '../store/store'
import ReactHealthCheckBox from './ReactHealthCheckBox'

const PatientCompleteQuestionPopUp = ({ store, selectedMask, onPatientCompleteClick, rhIntegrationChecked, onRhIntegrationCheckBoxChange }) => {

  const closePopUpModal = (completePatient = false) => {
    store.showPopUpToCompletePt = false
    if (completePatient) {
      onPatientCompleteClick()
      appStore.closeDetails()
    } else {
      // Call new api that records which user preferred NOT to complete
      appStore.saveUserIncompletions(store?.selectedRow?.website_token)
      appStore.closePatientDetailsModal()
    }
  }

  return (
    <>
      <Modal isOpen={store.showPopUpToCompletePt} toggle={() => closePopUpModal(false)} size='lg' centered>
        <ModalHeader toggle={() => closePopUpModal(false)}>Complete Patient</ModalHeader>
        <ModalBody>
          <div className='mb-2'>
            Do you want to complete (dispense) this patient with the <strong><u>{getMaskDisplayName(selectedMask)}</u></strong> mask?
          </div>
          {store?.currentSubdomain?.isRhParticipant &&
            <ReactHealthCheckBox
              checked={rhIntegrationChecked}
              onChange={onRhIntegrationCheckBoxChange}
            />
          }
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => closePopUpModal(true)}>Yes</Button>
          <Button color='secondary' onClick={() => closePopUpModal(false)}>No</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default collect(PatientCompleteQuestionPopUp)