/* eslint-disable */
import { store } from 'react-recollect';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import 'react-toastify/dist/ReactToastify.css';
import { cloneDeep } from 'lodash';
import { formatDateToString, isValidArray, patientDetailDate, maskIsMagnetic, convertToCustomerTimezone, getCurrentDomain, writeCookie, readCookieValue, updateCookieExpiration, subdomain, sendGetRequest, sendPostRequest, sendPutRequest } from '../utils/utils';
import axios from 'axios';
import refitQuestions from '../assets/refit_questions.json';
import maskData from '../masks.json';
import PatientChatSocketInstance from '../websockets/PatientChatSocket';
import dayjs from 'dayjs';

class stores {
  constructor() {
    store.alertsTotalRows = 999;
    store.alerts = [];
    store.alertsPageNumber = 1;
    store.patients = [];
    store.hasPatients = store.patients.length;
    store.notesTotalRows = 999;
    store.notesPageNum = 1;
    store.patient_shared_notes = [];
    store.patientsTotalRows = 999;
    store.allPatientMessages = [];
    store.patientMessagesTotalRows = 999;
    store.patientMessages = [];
    store.pt_msg_file = null;
    store.patientMessagesPageNum = 1;
    store.isPatientValidated = false;
    store.recallPatientNotes = [];
    store.initialPatients = [];
    store.patientStatuses = [];
    store.activeTab = 'masks';
    store.activeMasksTab = 'recommended';
    store.patientsPageNumber = 1;
    store.loading = false;
    store.initialApisLoading = false;
    store.usersLoading = false;
    store.locationsLoading = false;
    store.archiveLocationLoading = false;
    store.alertsLoading = false;
    store.patientAlertsLoading = false;
    store.ptMessagesFromThreadLoading = false;
    store.ptMessagesLoading = false;
    store.mfaLoading = false;
    store.docUploadLoading = false;
    store.twilioLookupLoading = false;
    store.onboardPatientLoading = false;
    store.verifyRecaptchaLoading = false;
    store.scriptedMaskLoading = false;
    store.editDataLoading = false;
    store.patientCompleteLoading = false;
    store.isCurrentVersionLoading = false;
    store.ssoLoginLoading = false;
    store.currentUser = '';
    store.currentSubdomain = {};
    store.subdomainNames = {};
    store.subdomainNpiNumbers = {};
    store.showPopUpToCompletePt = false;
    store.locationsSharedWithOptions = [];
    store.locationOptionGroups = [];
    store.isMobile = false;
    store.isTablet = false;
    store.userMfaSmsOptIn = false;
    store.settings = {
      showMaskToPatient: false,
      sendMessageOnComplete: false,
      mfaEnabled: true,
      patientMessagingEnabled: false,
      kioskModeEnabled: false,
      selfOnboardModeEnabled: false,
      patientAlertsEnabled: true,
      patientRecallToggleEnabled: false,
      sharedPatientsEnabled: false,
      nonRecommendedMaskEnabled: false,
      adHocEnabled: false,
      adHocAllowedRoles: ['super_administrator'],
    };
    store.isPtMessagingEnabled = false;// For pt side checking
    store.scanPackageEnabled = false;
    store.remainingScans = null;
    store.notificationSettings = {
      alert_notif_emails: [],
      alert_notif_gen: 'never',
      alert_notif_assign: 'never',
    }
    store.users = [];
    store.usersTotalRows = 999;
    store.usersPageNumber = 1;
    store.onlineChatUsers = [];
    store.usersSortedBy = null;
    store.patientAlerts = [];
    store.isPtAlertsCalled = false;
    store.statements = [];
    store.allMasks = false;
    store.masksByName = this.getMasksByName();
    store.maskBrandError = '';
    store.availableMaskOptionGroups = null;
    store.customerDetails = null;
    store.addUserSendEmail = true;
    store.selectedRow = {};
    store.newPatientFilter = null;
    store.allCustomers = [];
    store.customerUsernames = [];
    store.showEditLocationModal = false;
    store.showCreateLocationModal = false;
    store.selectedLocationId = '';
    store.selectedLocationName = '';
    store.followupModal = false;
    store.parentNote = null;
    store.selectedLocationTimezone = { value: "", label: "Default" };
    store.selectedLocationBranchNumber = '';
    store.settingsHistory = [];
    store.knowledge_base_history = [];
    store.isKbPostHistoryCalled = false;
    store.user_kb_videos_completed = [];
    store.kb_video_options = {};
    store.kbPostDetail = {};
    store.knowledgeBasePosts = [];
    store.kb_blog_image_file = null;
    store.scanCount = null
    store.sortBy = null
    // Remember to change initial values on resetFilters function too
    store.filters = {
      nameSearch: '',
      locations: [],
      customers: [],
      status: [],
      facilityType: [],
      invitationMode: [],
      startDate: null,
      endDate: null,
      internalPatients: false,
      resupplyPatients: false,
      sharedToPatients: false,
      sharedFromPatients: false,
      userEmail: [],
      dateType: 'created_at',
      physician: '',
      recallPtsOnly: false,
      recallPtsYes: null,
      recallPtNotesExists: '',
      recallPtNotesSearch: '',
      regions: [],
      patientAssignedTo: [],
      wasRefit: ''
    }
    store.alertFilters = {
      patientId: '',
      status: [],
      startDate: null,
      endDate: null,
      resolvedBy: [],
      locations: [],
      alertType: [],
      createdBy: [],
      assignedTo: []
    }
    store.patientMessagesFilters = {
      patientId: '',
      locations: [],
      startDate: null,
      endDate: null,
      messageType: [],
      messageStatus: [],
      createdBy: [],
      resolvedBy: [],
    }
    store.selectedFormularyName = null;
    store.selectedBranchPreferences = null;
    store.selectedLocationResupplyFormularyName = '';
    store.selectedLocationNewPatientFormularyName = '';
    store.formularies = null;
    store.isAllSelectedMasksMagnetic = false;
    store.isTooFewSelectedMasks = false;
    store.masksVarietyWarning = [];
    store.archivedFormularies = [];
    store.formularyNameOpts = [];
    store.simulationResults = null;
    store.simulationCushionSizes = null;
    store.simulationFrameSizes = null;
    store.simulationSizes = null;
    store.lastLoadPatientsTimestamp = null;
    store.showChartModal = false;
    store.showChartModalNested = false;
    store.showPatientsListModal = false;
    store.formularyListModalData = {
      show: false,
      filterBy: ''
    };
    store.patientSurveyAnswers = {
      did_receive_pap: false,
      had_contacted_physician: false,
      had_unscheduled_visit: false,
      did_switch_mask: false,
      mask_switched_to: false,
      did_switch_pressure: false,
      pressure_switched_to: false,
      increased_health_benefits: false,
      physician_recommended_bipap: false,
      health_benefits: [],
      had_new_symptoms: false,
      symptoms: [],
      age: false,
      gender: false,
      ethnicity: false,
      pap_received_type: false,
      supply_experience: 'poor',
      had_changed_pap_supplies: false,
      sleep_study_type: 'home',
      physician_type: 'sleep_physician',
      contacted_sleepglad: false,
      using_humidifier: false,
      using_ramp: false,
      patient_feedback: '',
      hour_spent_estimate: false,
      night_spent_estimate: false,
      sleep_level: false,
      height_range: false,
      weight_range: false,
      employment: false,
      income: false,
      school_level: false,
      marital_status: false,
      how_often_pap_supplies_cleaned: false,
      pap_supplies_cleaner: false
    }
    store.patientConfig = {
      invalidWebsiteToken: false,
      client_url: '',
      client_phone: '',
      client_name: '',
      isPatientSurveyComplete: false,
      isPatientSurveyStarted: false
    }
    store.patientSurveyOptions = {
      mask_switched_to: false,
      size_switched_to: false,
      switch_reason: false,
      pressure_switched_to: false,
      health_benefits: false,
      symptoms: false,
      had_contacted_physician: false,
      pap_received_type: false,
      hour_spent_estimate: false,
      night_spent_estimate: false,
      had_contacted_physician: false,
      sleep_level: false,
      sleep_study_type: false,
      physician_type: false,
      scanning_experience: false,
      supply_experience: false,
      gender_other: false
    }
    store.showPatientSurveyModal = false;
    store.chartModalData = {
      title: 'No Data Available',
      filterBy: '',
      startDate: '',
      endDate: '',
      hasNestedModal: false,
    };
    store.patientsListModalData = {
      title: 'No Data Available',
      logged_in_subdomain: '',
      filterBy: '',
      stat: '',
      chartType: 'ColumnChart',
      startDate: '',
      endDate: '',
    }
    store.chartModalDataNested = {
      title: 'No Data Available',
      filterBy: '',
      stat: '',
      chartType: 'PieChart',
      startDate: '',
      endDate: '',
      hasNestedPatientsListModal: false,
      hasNestedModal: false,
    }
    store.chartModalDataNestedChild = {
      title: 'No Data Available',
      filterBy: '',
      stat: '',
      chartType: 'PieChart',
      startDate: '',
      endDate: '',
      hasNestedPatientsListModal: false,
      hasNestedModal: false,
    }
    store.weeklyScanDropdown = [];
    store.showChartModalDataNestedChild = false;
    store.chartDomain = false;
    store.notificationInfo = {
      magneticRefitCount: 0,
      surveyCompletionCount: 0,
      knowledgeBasePostsForCount: [],
      knowledgeBasePostUserHistory: [],
      unreadAlerts: 0,
      unreadPtMessagesCount: 0,
      websiteTokensWithUnreadMessages: []
    };
    store.patientNotificationCount = 0;
    store.copied_formularies = [];
    store.sharedLocResults = [];
    store.locationsSharedWith = [];
    store.myActivities = {
      messages: [],
      alerts: [],
    }
    store.profileEdit = false;
    store.adHocApiData = []; // data we send with api to get report
    store.isAdHocLoading = false;
    store.onboardPatientSuccess = false;
    store.onboardPatientURL = '';
    store.blacklistedEmails = [];
    store.maskData = [];
    store.bulkReinvitesLoading = false;
    store.showInPersonModal = false;
    store.inPersonModePatient = {};
    store.currentPatientTablePage = 1;
    store.initialApisCalled = false;
    store.scriptedPhysicians = [];
    store.scriptedPhysiciansLoading = false;
    store.twilioAvailablePhoneNumbers = [];
    store.downloadsLoading = false;
    store.totalDownloads = 0;
    store.downloadsPageNumber = 1;
    store.includeCurrentAnalyticsPeriod = false;

    this.knowledgeBaseUpdateData();
    this.resetNewPatient();
  }

  resetNewPatient = (inviteChannel) => {
    console.log('Initializing Add New Patient form')
    store.newPatient = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      number: '',
      message: '',
      notes: '',
      inviteChannel: inviteChannel,
      isRecall: false,
      location: '',
      subdomain: '',
      language: 'en',
      setUpDate: '',
      patientAssignedTo: '',
      isFirstTime: true,
      isScripted: false,
      bmi: '',
      sleepStudyType: '',
      isMobile: '',
      birthDate: '',
      ethnicity: '',
      prescribed_mask: '',
      prescribed_mask_other: null,
      prescribed_mask_cushion_size: '',
      prescribed_mask_frame_size: '',
      facilityType: '',
      allowDuplicate: false,
      physicianSubdomain: '',
      formularyMaskList: []
    };
    store.errorMessage = '';
  }

  login = async (data) => {
    store.loading = true
    const domain = getCurrentDomain()
    try {
      let resp = await axios.post('/rest/login', data);
      console.log('Got login response: ', resp)
      store.userMfaSmsOptIn = resp.data.mfa_sms_opt_in
      store.userMfaChannel = resp.data.mfa_channel
      store.isMfaEnabled = resp.data.is_mfa_enabled
      if (resp.data.is_mfa_enabled) store.userPhone = resp.data?.phone
      if (resp.data.redirect_to) {
        window.location.href = encodeURI(resp.data.redirect_to);
        return false
      }
      // Store token in cookie for domain
      writeCookie('token', resp.data.token, domain);
      localStorage.setItem('login_type', 'regular');
      localStorage.setItem('passwordResetRequired', resp.data.password_reset_required);

      // If all processes complete successfully, set the environment for MFA validation

      // Set isVerified according to MFA toggle
      localStorage.setItem('isVerified', !resp.data.is_mfa_enabled)
    } catch (err) {
      store.loading = false;
      console.log(err.response)
      let msg = 'Error logging in';
      if (err.response && err.response.data && err.response.data.error) {
        msg = err.response.data.error;
      }
      toast.error(msg);
      return false
    }
    console.log('Successfully called /rest/login')

    // If MFA disabled, user already verified and can go to next page
    if (JSON.parse(localStorage.getItem('isVerified')) === true) {
      await this.getCurrentVersion()
      updateCookieExpiration('token', domain, 10)
      store.loading = false
      return true
    }

    // Initiate MFA process:
    // if cached MFA found, process login directly
    // else, proceed for MFA validation
    try {
      // First, look for cached token
      data.cached_token = readCookieValue('token')

      data.mfaChannel = store.userMfaChannel
      data.userPhone = store.userPhone
      const resp = await this.sendMfaCode(data, true)
      console.log('Got MFA gen reponse: ', resp)
      if (resp.data.is_mfa_cached === true) {
        localStorage.setItem('isVerified', true)
        await this.getCurrentVersion()
        updateCookieExpiration('token', domain, 10)
        store.loading = false
        return true
      }
    } catch (err) {
      store.loading = false;
      console.log(err.response)
      let msg = 'Error logging in';
      if (err.response && err.response.data && err.response.data.error) {
        msg = err.response.data.error;
      }
      toast.error(msg);
      return false
    }

    // Prompt user with toast for MFA validation
    if (JSON.parse(localStorage.getItem('isVerified')) === false) {
      toast.success(`Authentication code sent to your ${store.userMfaChannel === 'sms' ? 'mobile' : 'email'}`)
      store.loading = false
    }

    return true
  }

  sendMfaCode = async (data, fromLoginFunc = false) => {
    if (!fromLoginFunc) store.mfaLoading = true
    try {
      const resp = await sendPostRequest('/rest/generate_2fa', data)
      store.mfaLoading = false
      if (!fromLoginFunc) {
        toast.success(`
            Authentication code sent to your ${data.mfaChannel === 'sms' ? 'mobile' : 'email'}
          `)
      }
      return resp
    } catch (err) {
      this.handleError(err)
    }
    store.mfaLoading = false
  }

  validate2fa = async (data) => {
    store.loading = true
    const domain = getCurrentDomain()
    // Validate MFA and send to /dashboard on success
    try {
      let resp = await sendPostRequest('/rest/validate_2fa', data)
      console.log('Got MFA validation reponse: ', resp)
      updateCookieExpiration('token', domain, 10)
    } catch (err) {
      store.loading = false;
      console.log(err.response)
      let msg = 'Error validating MFA';
      if (err.response && err.response.data && err.response.data.error) {
        msg = err.response.data.error;
      }
      toast.error(msg);
      return false
    }

    store.loading = false
    localStorage.setItem('isVerified', true)

    // Check if password needs to be reset
    console.log('Successfully validated through /rest/validate_2fa')
    if (localStorage.passwordResetRequired == 'true') {
      console.log('Password reset required');
      window.location.href = '/settings/change';
      return false
    }

    await this.getCurrentVersion()
    window.location.href = localStorage.getItem('path') || '/dashboard'
    return true
  }

  getCurrentVersion = async () => {
    store.isCurrentVersionLoading = true
    try {
      let resp = await sendGetRequest('/rest/get_current_version');
      const currentVersion = resp.data.current_version;
      if (!localStorage.currentVersion) {
        console.log('Initial visit, loading SleepGlad client version ', currentVersion);
        localStorage.setItem('currentVersion', currentVersion);
      }
      if (localStorage.currentVersion !== currentVersion) {
        toast.success(`We are updating your browser with SleepGlad's latest version.  Please stand by.`)
        localStorage.setItem('currentVersion', currentVersion);
        window.location.reload(true);
      }
    } catch (err) {
      console.log('Error on get_current_version: ', err);
    }
    store.isCurrentVersionLoading = false
  };

  initializeCurrentUser = async () => {
    store.loading = true
    try {
      let resp = await sendGetRequest('/rest/get_current_user');
      store.currentUser = resp.data;
      store.userMfaSmsOptIn = resp.data.userPreferences?.mfa_sms_opt_in
      console.log('Current user is: ', store.currentUser)
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false
  };

  callInitialAPIs = async () => {
    store.initialApisLoading = true
    await Promise.all([
      this.initializeCurrentUser(),
      this.initializeCurrentSubdomain(),
      this.getLocations()
    ])
    store.initialApisCalled = true;
    store.initialApisLoading = false
    this.getLocationOptions()
  }

  initializeCurrentSubdomain = async () => {
    try {
      const resp = await sendGetRequest('/rest/get_current_subdomain');
      const { branches } = resp.data
      store.currentSubdomain = { ...store.currentSubdomain, ...resp.data }
      branches.forEach(branch => {
        store.subdomainNames[branch.subdomain] = branch.name_formatted
        store.subdomainNpiNumbers[branch.subdomain] = {
          npi_number: branch.npi_number,
          is_facility: branch.is_facility,
          is_practice: branch.is_practice,
        }
        if (branch.affiliates) {
          store.subdomainNpiNumbers[branch.subdomain]['affiliates'] = branch.affiliates;
        }
      })

      // System Notification
      this.handleSystemNotification(store.currentSubdomain.system_notification)

    } catch (error) {
      this.handleError(error)
    }
  }

  getLocations = async () => {
    store.locationsLoading = true
    try {
      const resp = await sendGetRequest('/rest/locations');
      store.currentSubdomain = { ...store.currentSubdomain, ...resp.data }
    } catch (error) {
      this.handleError(error)
    }
    store.locationsLoading = false
  }

  moveLocation = async (location_id, new_subdomain) => {
    try {
      await sendPostRequest('/rest/move_location', { location_id, new_subdomain })
    } catch (err) {
      this.handleError(err)
    }
  }

  archiveLocation = async (locationId, dbaName, shortName) => {
    store.archiveLocationLoading = true
    try {
      await sendPostRequest('/rest/archive_location', { locationId, dbaName, shortName })
      this.getLocations()
      toast.info('Location has been archived.')
    } catch (error) {
      this.handleError(error)
    }
    store.archiveLocationLoading = false
  }

  // Logged in only if token exists and is 2FA is verified
  isLoggedIn = () => {
    return !!readCookieValue('token') && JSON.parse(localStorage.getItem('isVerified')) === true
  }

  isLoaded = () => {
    return !!store.patients && !!store.formularies
  }

  logout = async () => {
    localStorage.setItem('isVerified', false)
    localStorage.removeItem('login_type')
    window.location.href = '/login'
  };

  isPasswordResetRequired = () => {
    return localStorage.passwordResetRequired == 'true';
  };

  setFilterStartDate(date) {
    console.log('Setting filter start date to ', date);
    store.filters.startDate = date
  }

  setFilterEndDate(date) {
    console.log('Setting filter end date to ', date);
    store.filters.endDate = date
  }

  resetcurrentPatientTablePage = () => {
    store.currentPatientTablePage = 1;
  }

  setcurrentPatientTablePage = (pageNumber) => {
    store.currentPatientTablePage = pageNumber;
  }

  getcurrentPatientTablePage = () => {
    return store.currentPatientTablePage;
  }

  resetFilters = () => {
    store.newPatientFilter = false;
    store.filters = {
      nameSearch: '',
      locations: [],
      regions: [],
      customers: [],
      status: [],
      facilityType: [],
      invitationMode: [],
      startDate: null,
      endDate: null,
      internalPatients: false,
      resupplyPatients: false,
      sharedToPatients: false,
      sharedFromPatients: false,
      userEmail: [],
      dateType: 'created_at',
      physician: '',
      recallPtNotesExists: '',
      recallPtNotesSearch: '',
      regions: [],
      patientAssignedTo: [],
      wasRefit: ''
    };
  }

  applyFilters = () => {
    this.resetcurrentPatientTablePage()
  }

  setShowChartModal = isShowing => {
    store.showChartModal = isShowing;
  }

  setShowPatientsListModal = isShowing => {
    store.showPatientsListModal = isShowing;
  }

  setFormularyListModalData = data => {
    store.formularyListModalData = data;
  }

  setShowChartModalNested = isShowing => {
    store.showChartModalNested = isShowing;
  }

  setShowChartModalNestedChild = isShowing => {
    store.showChartModalNestedChild = isShowing;
  }

  setChartDomain = domain => {
    store.chartDomain = domain;
  }

  setChartModalDataNestedChild = (filterBy, stat, startDate, endDate) => {
    let title;
    let updatedStat;
    let hasNestedPatientsListModal;
    let hasNestedModal;
    let chartType;
    switch (stat) {

      case "user-mfr-options-recommendations":
        title = `${filterBy['brand']} Masks Recommended for ${filterBy['mask_option']}`;
        chartType = "PieChart";
        updatedStat = "user-mfr-mask-options-recommendations";
        hasNestedPatientsListModal = true;
        hasNestedModal = false;
        break;
    }
    store.chartModalDataNestedChild = { filterBy, stat: updatedStat, chartType, startDate, endDate, hasNestedPatientsListModal, hasNestedModal, title }
  }

  getPhysicianOptions() {
    const subdomainNpiNumbers = store.subdomainNpiNumbers;
    const subdomainNames = store.subdomainNames;
    let options = [];
    for (const subdomain in subdomainNpiNumbers) {
      const npiData = subdomainNpiNumbers[subdomain];
      if (!npiData.is_facility && !npiData.is_practice) {
        options.push({
          value: subdomain,
          label: subdomainNames[subdomain],
        });
      }
    }
    return options;
  }

  getLocationOptions = (selectedSubdomains) => {
    const subdomainLocations = store.currentSubdomain.subdomainLocations;
    const subdomainNames = store.subdomainNames;
    let locationOptionGroups = [];
    if (subdomainLocations && Object.keys(subdomainLocations).length) {
      for (const subdomain in subdomainLocations) {
        if (selectedSubdomains && !selectedSubdomains.includes(subdomain))
          continue;
        let options = [];
        for (const location of subdomainLocations[subdomain]) {
          options.push({
            value: [subdomain, location.id],
            label: location.name,
            isHidden: location.is_hidden
          });
        }
        locationOptionGroups.push({
          label: subdomainNames[subdomain],
          options: options,
        });
      }
    }
    store.locationOptionGroups = locationOptionGroups
  }

  setChartModalDataNested = (filterBy, stat, chartType, startDate, endDate) => {
    let title;
    let updatedStat;
    let hasNestedPatientsListModal;
    let hasNestedModal;
    switch (stat) {

      case "mfr-options-recommendations":
        title = `${filterBy['brand']} Masks Recommended for ${filterBy['mask_option']}`;
        updatedStat = "mfr-mask-options-recommendations";
        hasNestedPatientsListModal = true;
        hasNestedModal = false;
        break;

      case "refit-mask-type-count":
        title = `${filterBy['brand']} ${filterBy['mask-type']} Masks Refit`;
        updatedStat = 'refit-mfr-mask-type-count';
        hasNestedPatientsListModal = false;
        hasNestedModal = false;
        break;

      case "user-mask-options-recommendations":
        title = `Manufacturer Recommended for ${filterBy['mask_option']}`;
        updatedStat = "user-mfr-options-recommendations";
        hasNestedPatientsListModal = false;
        hasNestedModal = true;
        break;
    }
    store.chartModalDataNested = { title, filterBy, stat: updatedStat, chartType, startDate, endDate, hasNestedPatientsListModal, hasNestedModal }
  }

  setPatientsListModalData = (filterBy, stat, startDate, endDate) => {
    let updatedStat;
    let title;
    switch (stat) {

      case "patient-survey-new-symptoms":
        updatedStat = "survey-pts-symptoms";
        title = filterBy['had_new_symptoms'] ? 'Patients Who Noticed New Symptoms' : 'Patients Who Did Not Notice Symptoms';
        break;
      case "patient-survey-increased-health-benefits":
        updatedStat = "survey-pts-health-benefits";
        title = filterBy['increased_health_benefits'] ? 'Patients Who Noticed Increased Health Benefits' : 'Patients Who Did Not Notice Health Benefits';
        break;
      case "patient-survey-pressure-changed-to":
        updatedStat = "survey-pts-did-switch-pressure";
        title = filterBy['did_switch_pressure'] ? `Patients Who Switched Pressure to ${filterBy['pressure']}` : 'Patients Who Did Not Switched Pressure';
        break;
      case "patient-survey-mask-changed-to":
        updatedStat = "survey-pts-did-switch-mask";
        title = filterBy['did_switch_mask'] ? `Patients Who Switched Mask to ${filterBy['mask']}` : 'Patients Who Did Not Switched Mask';
        break;
      case "patient-survey-using-chinstrap":
        updatedStat = 'survey-pts-using-chinstrap';
        title = filterBy['using_chinstrap'] ? 'Patients Using Chinstrap' : 'Patients Not Using Chinstrap'
        break;
      case "patient-survey-replaced-supplies":
        updatedStat = "survey-pts-replaced-supplies";
        title = filterBy['had_changed_pap_supplies'] ? "Patients Who Replaced PAP Supplies" : "Patients Who Did Not Replace PAP Supplies"
        break;
      case "patient-survey-pap-received-type":
        updatedStat = 'survey-pts-pap-received-type';
        title = filterBy['pap_received_type'] === 'mailed' ? 'Patients Who Received PAP by Mail' : 'Patients Who Received PAP from Office'
        break;
      case "patient-survey-unexpected-md-follow-up":
        updatedStat = 'survey-pts-unexpected-md-follow-up';
        title = filterBy['unexpected_visit'] ? 'Patients Who Had an Unexpected MD Visit' : 'Patients Who Did Not Have an Unexpected MD Visit';
        break;
      case "patient-survey-md-follow-up":
        updatedStat = 'survey-pts-md-follow-up';
        title = filterBy['followed_up'] ? 'Patients Followed Up With' : 'Patients Not Followed Up With';
        break;
      case "patient-survey-pap-usage":
        updatedStat = "survey-pts-pap-usage";
        title = filterBy['still_using_pap'] ? 'Patients Using PAP' : `Patients Not Using PAP`;
        break;
      case "patient-survey-hours-spent-estimate":
        updatedStat = "survey-pts-hours-spent-estimate";
        title = `Patients who spend ${filterBy['hours']} hours using PAP`;
        break;
      case "patient-survey-dme-followed-up":
        updatedStat = "dme-followed-up-patients"
        title = filterBy['followed_up'] ? 'DME Followed Up With' : 'DME Did Not Follow Up With';
        break;
      case "scripted-masks-by-physician-not-in-formulary":
        updatedStat = "scripted-patients-by-mask-physician-not-in-formulary";
        title = `Patients Prescribed ${filterBy['mask']} from ${filterBy['user']}`;
        break;
      case "masks-most-refit-switch-reason":
        updatedStat = "masks-most-refit-patients";
        title = `Patients Refit From ${filterBy['mask']}`
        break;

      case "user-mfr-mask-options-recommendations":
        updatedStat = "user-patients-recommended-mask";
        title = `Patients Recommended ${filterBy['mask']}`;
        break;

      case "mfr-mask-options-recommendations":
        updatedStat = "patients-recommended-mask";
        title = `Patients Recommended ${filterBy['mask']}`;
        break;

      case "refit-mask-count":
        updatedStat = "mask-patients-list-refit";
        title = `Patients Refit for ${filterBy['mask']}`;
        break;

      case "mask-count":
        updatedStat = "mask-patients-list";
        title = `Patients Recommended ${filterBy['mask']}`;
        break;

      case "mask-count-new-patients":
        updatedStat = "mask-patients-list-new";
        title = `New Patients Recommended ${filterBy['mask']}`;
        break;

      case "mask-count-resupply":
        updatedStat = "mask-patients-list-resupply";
        title = `Resupply Patients Recommended ${filterBy['mask']}`;
        break;

      case "mask-count-completed":
        updatedStat = "mask-patients-list-completed";
        title = `Dispensed Patients Recommended ${filterBy['mask']}`;
        break;

      case "monthly-processed-scan-count":
        updatedStat = "processed-patients-list";
        title = `Patients Processed by ${filterBy['name']}`;
        break;

      case "monthly-pending-scan-count":
        updatedStat = "pending-patients-list";
        title = `Patients Pending by ${filterBy['name']}`;
        break;

      case "monthly-refit-scan-count":
        updatedStat = "refit-patients-list";
        title = `Patients Refit by ${filterBy['name']}`;
        break;

      case "monthly-override-scan-count":
        updatedStat = "override-patients-list";
        title = `Override Patients by ${filterBy['name']}`;
        break;

      case "total-delivered-patient-count":
        updatedStat = "delivered-patients-list";
        title = `Patients with ${filterBy['name']} status`;
        break;

      case "total-failed-patient-count":
        updatedStat = "failed-patients-list";
        title = `Patients with ${filterBy['name']} status`;
        break;

      case "total-scanerror-patient-count":
        updatedStat = "scan-error-patients-list";
        title = `Patients with ${filterBy['name']} status`;
        break;

      case "total-expired-patient-count":
        updatedStat = "expired-patients-list";
        title = `Patients with ${filterBy['name']} status`;
        break;

      case "month-refit-reasons-by-clinician":
        updatedStat = "patients-list-by-refit-reason"
        title = `Refit Patients`
        break;
      case "refit-to-full-face-mask-reasons":
        updatedStat = "refit-to-full-face-mask-reason-patients"
        title = `Patients With Reason ${filterBy['reason']}`
        break;
      case "month-status-count-per-user":
        updatedStat = "patient-list-by-user-status-month"
        title = `Patients With Reason ${filterBy['reason']}`
        break;
      case "monthly-pending-patient-count":
        updatedStat = "monthly-pending-patients"
        title = `Pending Patients`
        break;
      case "monthly-incomplete-patient-count":
        updatedStat = "monthly-incomplete-patients"
        title = `Incomplete Patients`
        break;
      case "magnetic-mask-refit":
        updatedStat = "magnetic-mask-refit-patients"
        title = `Patients With Magnetic Mask Refit`
        break;
    }
    filterBy = { ...filterBy, logged_in_subdomain: store.currentUser.customerSubdomain }
    store.patientsListModalData = { stat: updatedStat, title, filterBy, startDate, endDate };
  }

  setChartModalData = (filterBy, stat, subdomain, startDate, endDate) => {
    let updatedStat;
    let chartType;
    let title;
    let filter;
    let hasNestedModal;
    switch (stat) {

      case "patient-survey-symptoms":
        updatedStat = 'patient-survey-new-symptoms';
        title = "Patients Who Noticed New Symptoms";
        filter = { ...filterBy, "subdomain": subdomain };
        chartType = "PieChart";
        hasNestedModal = true;
        break;

      case "patient-survey-health-benefits":
        updatedStat = 'patient-survey-increased-health-benefits';
        title = "Patients Who Noticed Health Benefits";
        filter = { ...filterBy, "subdomain": subdomain };
        chartType = "PieChart";
        hasNestedModal = true;
        break;

      case "patient-survey-still-using-pap":
        updatedStat = 'patient-survey-pap-usage';
        title = filterBy['still_using_pap'] ? "Hours Spent Per Night Using PAP" : "Reasons for Patients Not Using PAP";
        filter = { ...filterBy, "subdomain": subdomain };
        chartType = "PieChart";
        hasNestedModal = true;
        break;

      case "scripted-masks-not-in-formulary":
        updatedStat = 'scripted-masks-by-physician-not-in-formulary';
        title = `Physicians who have prescribed ${filterBy['mask']}`;
        chartType = "PieChart";
        filter = { ...filterBy, "subdomain": subdomain };
        hasNestedModal = true;
        break;

      case "patient-survey-mask-change":
        updatedStat = "patient-survey-mask-changed-to";
        title = `Patients Who Changed Their Mask`;
        chartType = "PieChart";
        filter = { ...filterBy, "subdomain": subdomain };
        hasNestedModal = true;
        break;

      case "patient-survey-pressure-change":
        updatedStat = "patient-survey-pressure-changed-to";
        title = `Patients Who Changed Their Pressure`;
        chartType = "PieChart";
        filter = { ...filterBy, "subdomain": subdomain };
        hasNestedModal = true;
        break;

      case "masks-most-refit":
        updatedStat = "masks-most-refit-switch-reason";
        title = `Reasons Switched From ${filterBy}`;
        chartType = "BarChart";
        filter = {
          "mask": filterBy,
          "subdomain": subdomain
        }
        hasNestedModal = true;
        break;

      case "mask-recommendations":
        updatedStat = "mfr-options-recommendations";
        title = "Manufacturer Recommended for " + filterBy;
        chartType = "PieChart";
        filter = {
          "mask_option": filterBy,
          "subdomain": subdomain
        }
        hasNestedModal = true;
        break;

      case "user-mask-recommendations":
        updatedStat = "user-mask-options-recommendations"
        title = filterBy + " Mask Option Recommendations"
        chartType = "PieChart";
        filter = {
          "user": filterBy,
          "subdomain": subdomain
        }
        hasNestedModal = true;
        break;

      case "refit-mfr-count":
        updatedStat = "refit-mask-type-count"
        title = filterBy + " Refit Mask Type Count"
        chartType = "PieChart";
        filter = {
          "brand": filterBy,
          "subdomain": subdomain
        }
        hasNestedModal = true;
        break;

      case "mfr-type-count":
        updatedStat = "mask-count";
        title = filterBy + " Recommended Mask Count(Total Patients)";
        chartType = "PieChart";
        filter = {
          "brand": filterBy,
          "subdomain": subdomain,
        };
        hasNestedModal = true;
        break;

      case "mfr-type-count-new-patients":
        updatedStat = "mask-count-new-patients";
        title = filterBy + " Recommended Mask Count(New Patients)";
        chartType = "PieChart";
        filter = {
          "brand": filterBy,
          "subdomain": subdomain,
        };
        hasNestedModal = true;
        break;

      case "mfr-type-count-resupply":
        updatedStat = "mask-count-resupply";
        title = filterBy + " Recommended Mask Count(Resupply Patients)";
        chartType = "PieChart";
        filter = {
          "brand": filterBy,
          "subdomain": subdomain,
        };
        hasNestedModal = true;
        break;

      case "mfr-type-count-completed":
        updatedStat = "mask-count-completed";
        title = filterBy + " Recommended Mask Count(Dispensed Patients)";
        chartType = "PieChart";
        filter = {
          "brand": filterBy,
          "subdomain": subdomain,
        };
        hasNestedModal = true;
        break;

      case "month-invitation-count-1":
        updatedStat = "monthly-processed-scan-count"
        title = "Processed Scans for " + filterBy;
        chartType = "ColumnChart";
        filter = {
          "month": filterBy,
          "subdomain": subdomain,
        }
        hasNestedModal = true;
        break;

      case "month-invitation-count-2":
        updatedStat = "monthly-pending-scan-count"
        title = "Pending Scans for " + filterBy;
        chartType = "ColumnChart";
        filter = {
          "month": filterBy,
          "subdomain": subdomain,
        }
        hasNestedModal = true;
        break;

      case "month-invitation-count-3":
        updatedStat = "monthly-refit-scan-count"
        title = "Refit Scans for " + filterBy;
        chartType = "ColumnChart";
        filter = {
          "month": filterBy,
          "subdomain": subdomain,
        }
        hasNestedModal = true;
        break;

      case "month-invitation-count-4":
        updatedStat = "monthly-override-scan-count"
        title = "Override Scans for " + filterBy;
        chartType = "ColumnChart";
        filter = {
          "month": filterBy,
          "subdomain": subdomain,
        }
        hasNestedModal = true;
        break;

      case "monthly-completed-scan-count":
        updatedStat = "month-scan-count-per-user"
        title = `Scan Progress for ${filterBy['created_by_formatted']}(${filterBy['created_at']})`
        chartType = "ColumnChart"
        filter = { ...filterBy, "subdomain": subdomain }
        hasNestedModal = false;
        break;
      case "clinician-wise-refit-rate":
        updatedStat = "month-refit-reasons-by-clinician"
        title = `Refit Reasons for ${filterBy['created_at']}`
        chartType = "PieChart"
        filter = { ...filterBy, "subdomain": subdomain }
        hasNestedModal = true;
        break;

      case "month-status-count":
        updatedStat = "month-status-count-per-user"
        title = `${filterBy['status']} Patients during ${filterBy['month']}`
        chartType = "ColumnChart"
        filter = { ...filterBy, "subdomain": subdomain }
        hasNestedModal = true;
        break;

      case "refit-to-full-face-mask":
        updatedStat = "refit-to-full-face-mask-reasons"
        title = `Full Face Mask Refit Reasons for ${filterBy['created_at']}`
        chartType = "PieChart"
        filter = { ...filterBy, "subdomain": subdomain }
        hasNestedModal = true;
        break;

    }
    store.chartModalData = { title, filterBy: filter, stat: updatedStat, chartType, startDate, endDate, hasNestedModal };
  }

  getDefaultReminderDelaySeconds = async () => {
    store.loading = true;
    try {
      let resp = await sendGetRequest('/rest/administrator/default_reminder_delay_seconds',);
      const { data } = resp.data;
      store.defaultReminderDelaySeconds = data;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  getSettings = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/administrator/settings'
      const { data } = await sendGetRequest(url, { cancelToken: cancelToken?.token })
      const { defaultReminderDelaySeconds, numberOfReminders, sendPatientSurvey, requiredFields, requiredResupplyFields, allowed_mfrs, showRemainingMasks, showDispenseAsScripted } = data.settings
      store.settings = data.settings;
      store.currentSubdomain = {
        ...store.currentSubdomain,
        defaultReminderDelaySeconds,
        numberOfReminders,
        sendPatientSurvey,
        requiredFields,
        requiredResupplyFields,
        allowed_mfrs,
        showRemainingMasks,
        showDispenseAsScripted
      }
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  setSettings = async () => {
    store.loading = true;
    try {
      const { data } = await sendPostRequest('/rest/administrator/settings', {
        ...store.settings, created_by: store.currentUser.username
      })
      for (const setting in data.settings) {
        if (data.settings[setting]) store.settings[setting] = data.settings[setting];
      }
      toast.success('Settings have been saved.')
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }
  updateSettings = (newSettings) => {
    store.settings = newSettings;
  }

  setSendPatientSurvey = async sendPatientSurvey => {
    store.loading = true;
    try {
      let resp = await sendPostRequest('/rest/administrator/schedule_survey_on_invite',
        { schedule_survey_on_invite: sendPatientSurvey, created_by: store.currentUser.username });
      const { data } = resp.data;
      store.currentSubdomain.sendPatientSurvey = data;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  getNotificationSettings = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/administrator/notification_settings'
      const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      for (let setting in resp.data) {
        if (resp.data[setting]) store.notificationSettings[setting] = resp.data[setting]
      }
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }
  setNotificationSettings = async () => {
    store.loading = true;
    try {
      const resp = await sendPostRequest('/rest/administrator/notification_settings', {
        ...store.notificationSettings,
        created_by: store.currentUser.username
      });

      for (let setting in resp.data) {
        if (resp.data[setting]) store.notificationSettings[setting] = resp.data[setting]
      }
      toast.success("Notification settings changed successfully.")
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }
  updateNotificationSettings = (newSettings) => {
    store.notificationSettings = newSettings;
  }

  setDefaultReminderDelaySeconds = async (defaultReminderDelaySeconds) => {
    store.loading = true;
    try {
      await sendPostRequest(
        '/rest/administrator/default_reminder_delay_seconds',
        { default_reminder_delay_seconds: defaultReminderDelaySeconds },
      );
      store.currentSubdomain.defaultReminderDelaySeconds = defaultReminderDelaySeconds;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  setNumberOfReminders = async (numberOfReminders) => {
    store.loading = true;
    try {
      await sendPostRequest(
        '/rest/administrator/number_of_reminders',
        { number_of_reminders: numberOfReminders },
      );
      store.currentSubdomain.numberOfReminders = numberOfReminders;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  getPatientRequiredFields = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/administrator/required_fields'
      const res = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.currentSubdomain.requiredFields = res.data.data.required_fields;
      store.currentSubdomain.requiredResupplyFields = res.data.data.required_resupply_fields;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }
  savePatientRequiredFields = async type => {
    store.loading = true;
    try {
      const fields = type === 'required_fields' ? store.currentSubdomain.requiredFields : store.currentSubdomain.requiredResupplyFields;
      await sendPostRequest('/rest/administrator/required_fields', { type, fields });
      toast.success('Settings updated');
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  getPatientAllowedMfrs = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/administrator/allowed_manufacturers'
      const res = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.currentSubdomain.allowed_mfrs = res.data.data;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }
  savePatientAllowedMfrs = async () => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/administrator/allowed_manufacturers', store.currentSubdomain.allowed_mfrs);
      toast.success('Settings updated');
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  getFormulary = async (ptAssignedSubdomain = null, cancelToken = null) => {
    store.loading = true;
    try {
      let url = '/rest/formulary';
      if (ptAssignedSubdomain) url += `?ptAssignedSubdomain=${ptAssignedSubdomain}`;
      const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.formularies = { ...store.formularies, ...resp.data.formularies };
      // replace masks.json
      store.maskData = resp.data.customer_masks

      // Initialize the drop-down in Settings->Formulary
      if (!store.selectedFormularyName && Object.keys(store.formularies).length > 0) {
        store.selectedFormularyName = Object.keys(this.getActiveFormularies())[0];
      }
      store.formularyNameOpts = this.getFormularyNameOptions()
      store.brandPrefs = this.getBrandPrefs()
      store.currentFormulary = store.formularies[store.selectedFormularyName].masks_preferences;

    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  getActiveFormularies = () => {
    let activeFormularies = {}
    for (const name in store.formularies) {
      if (store.formularies[name].archived_at) continue;
      activeFormularies[name] = store.formularies[name];
    }
    return activeFormularies
  }

  getMasksByName = () => {
    let masksByName = {};
    for (const maskCategory in maskData) {
      for (const idx in maskData[maskCategory]) {
        const mask = maskData[maskCategory][idx];
        if (mask?.is_archived) continue
        masksByName[mask?.name] = mask;
      }
    }
    return masksByName;
  }

  getMaskCategory = (maskName) => {
    const mask = store.masksByName[maskName] || {};
    return mask.mask_category;
  }

  displayNameForMask = (maskName) => {
    const mask = store.masksByName[maskName];
    return mask?.mfr_display_name + ' ' + mask?.display_name;
  }

  getMasksPreferencesFromAttributes() {
    // Builds mask_preferences based on patient_attributes prefs data
    const maskData = store?.maskData;
    const attributesData = store.selectedRow.patient_attributes?.masks_preferences;

    const formularyMasks = Object.entries(maskData || {}).filter(([_, mask]) => attributesData.hasOwnProperty(mask.name))
    const masksPreferences = formularyMasks.map(([_, mask]) => ({
      mask_id: mask.mask_id,
      mask_name: mask.name,
      preference_score: attributesData[mask.name] || 0,
    }));
    return masksPreferences;
  }

  getAvailableMaskOptionGroups = async (fromPtDetails = false) => {
    const optionsFullFace = [];
    const optionsPillow = [];
    const optionsNasal = [];

    // First, get default formulary for a patient
    // If opening pt details, old patients will not have assigned formulary name so choose formulary name as per current assigned branch
    let selectedFormulary = this.getDefaultFormulary(fromPtDetails);

    // Now, for new patients formulary name will be provided so directly pick that (this handles the special case when a patient is assigned a non-default formulary)
    if (fromPtDetails && store.selectedRow.formularyName) {
      selectedFormulary = store.selectedRow.formularyName;
    } else if (!fromPtDetails && store.selectedFormularyName) {
      selectedFormulary = store.selectedFormularyName;
    }

    // In case this selected formulary is different from the list that we already have, we need to get the patient's own formularies as well to make sure we can get the selected formulary's masks
    if (!store.formularies[selectedFormulary]) {
      await this.getFormulary(store.selectedRow.subdomain)
    }

    const availableMasks = store.formularies[selectedFormulary]?.masks_preferences || this.getMasksPreferencesFromAttributes();
    for (const maskItem of availableMasks) {
      const mask = store.maskData[maskItem.mask_id];
      const item = {
        value: mask?.name,
        label: mask?.mfr_display_name + ' ' + mask?.display_name,
      };
      if (mask?.category == 'Full Face') {
        optionsFullFace.push(item);
      } else if (mask?.category == 'Nasal Pillow') {
        optionsPillow.push(item);
      } else {
        optionsNasal.push(item);
      }
    }
    store.availableMaskOptionGroups = [{
      label: 'Full Face',
      options: optionsFullFace,
    }, {
      label: 'Nasal',
      options: optionsNasal,
    }, {
      label: 'Pillow',
      options: optionsPillow,
    }];
  }

  getSelectedMasksVariety = (selectedMasks) => {
    // Masks Details
    const masks = selectedMasks.map((maskName) => {
      return {
        name: maskName,
        cat: this.getMaskCategory(maskName),
        isMagnetic: maskIsMagnetic(maskName)
      };
    });
    // By Category
    const selectedCatCount = masks.reduce((acc, item) => {
      acc[item.cat] = (acc[item.cat] || 0) + 1;
      acc.magneticMasks = (acc.magneticMasks || 0) + (item.isMagnetic ? 1 : 0);
      acc.totalMasks = (acc.totalMasks || 0) + 1;
      return acc;
    }, {});
    const categories = ['Full Face', 'Nasal', 'Nasal Pillow', 'magneticMasks', 'totalMasks'];
    const masksVarietyCount = categories.reduce((acc, curr) => {
      acc[curr] = selectedCatCount[curr] || 0;
      return acc;
    }, {});
    return masksVarietyCount;
  }

  validateMaskVariety = (selectedMasksVariety) => {

    let masksVarietyWarning = [];

    if (selectedMasksVariety["Nasal"] === 0) {
      masksVarietyWarning.push('noNasal');
    }
    if (selectedMasksVariety["Full Face"] === 0) {
      masksVarietyWarning.push('noFullFace');
    }
    if (selectedMasksVariety["Nasal Pillow"] === 0) {
      masksVarietyWarning.push('noNasalPillow');
    }
    if (selectedMasksVariety['totalMasks'] < 5) {
      masksVarietyWarning.push('lessThanFive');
    }
    if (selectedMasksVariety['totalMasks'] === selectedMasksVariety['magneticMasks']) {
      masksVarietyWarning.push('allMagnetic');
    }

    return masksVarietyWarning;
  }

  getDefaultFormulary = (fromPtDetails = false) => {
    // For calling from pt details, data should be fetched from selected row. Else get data from new patient object
    let isNewPatient = fromPtDetails ? store.selectedRow.is_first_time_cpap : store.newPatient.isFirstTime;
    let selectedLocation = fromPtDetails ? store.selectedRow.locationId : store.newPatient.location;
    let selectedSubdomain = fromPtDetails ? store.selectedRow.subdomain : store.newPatient.subdomain;
    let locationDetails;
    if (selectedSubdomain) {
      locationDetails = store?.currentSubdomain?.subdomainLocations[selectedSubdomain];
    }
    if (locationDetails && locationDetails.length) {
      for (let loc of locationDetails) {
        if (loc.id == selectedLocation) {
          return isNewPatient ? loc.new_patient_formulary : loc.resupply_formulary;
        }
      }
    }
    if (isNewPatient) {
      return 'Recommended New Patient Formulary'
    } else {
      return 'Recommended Resupply Optimization Formulary'
    }
  }

  setSelectedFormularyName = (name) => store.selectedFormularyName = name;

  setNewPatient = (data) => {
    store.newPatient = {
      ...store.newPatient,
      ...data
    }
  }

  getArchivedFormularies = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/administrator/archive_formulary'
      const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.archivedFormularies = resp.data.data;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  saveArchivedFormularies = async () => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/administrator/archive_formulary', store.archivedFormularies);
      toast.success('Settings updated')
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  getFormularyNameOptions(include_archived = false) {
    let formularyOptions = []
    const formularies = include_archived ? store.formularies : this.getActiveFormularies()
    for (let name in formularies) {
      formularyOptions.push({ value: name, label: name, belongsToALocation: formularies[name].belongs_to_a_location })
    }
    return formularyOptions;
  };

  setCopiedFormulary = async (data) => {
    store.loading = true;
    try {
      let resp = await sendPostRequest('/rest/copy_formulary', data);
      toast.success('Formulary Copied to Clipboard')
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  getCopiedFormularies = async (cancelToken) => {
    store.loading = true;
    try {
      let resp = await sendGetRequest('/rest/copy_formulary', { cancelToken: cancelToken?.token });
      store.copied_formularies = resp.data.data;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  setFormularyMaskSelected = (maskId, isSelected) => {
    // Preferences
    var maskList = store.currentFormulary;
    maskList = maskList.filter(mask => mask.mask_id && mask.mask_id !== maskId)
    if (isSelected) {
      maskList.push({ 'mask_id': maskId, 'mask_name': store.maskData[maskId].name, 'preference_score': 100 });
    }
    store.currentFormulary = maskList;

    // Enabled masks
    const maskNames = maskList.map((mask) => mask.mask_name);

    // Handle Variety
    var maskVariety = this.getSelectedMasksVariety(maskNames)
    store.masksVarietyWarning = this.validateMaskVariety(maskVariety)
  }

  setFormularyMaskPreference = (maskId, preferenceScore) => {
    var maskList = store.currentFormulary
    const updatedMaskList = maskList.map(mask => {
      if (mask.mask_id === maskId) {
        return { ...mask, preference_score: preferenceScore };
      }
      return mask;
    });
    store.currentFormulary = updatedMaskList;
  }

  saveFormulary = async (newFormulary = false) => {
    const formularyName = store.selectedFormularyName;
    console.log('Saving formulary: ', formularyName);
    const enabled_masks = store.currentFormulary.length > 0 ? store.currentFormulary.map(mask => mask ? mask.mask_name : null).filter(name => name !== null) : [];
    store.loading = true;
    try {
      var data = {};
      data[formularyName] = {
        'archived_at': store.formularies[formularyName]?.archived_at,
        'belongs_to_a_location': store.formularies[formularyName]?.belongs_to_a_location,
        'formulary_id': store.formularies[formularyName]?.formulary_id,
        'enabled_masks': enabled_masks,
        'masks_preferences': store.currentFormulary
      }

      if (newFormulary) {
        data['new_formulary'] = formularyName;
      }
      const resp = await sendPostRequest('/rest/formulary', data);
      store.formularies = { ...store.formularies, ...resp.data.formularies };

      // Update Settings->Formulary dropdown
      if (!store.selectedFormularyName && Object.keys(store.formularies).length > 0) {
        store.selectedFormularyName = Object.keys(this.getActiveFormularies())[0];
      }
      store.formularyNameOpts = this.getFormularyNameOptions()
      store.brandPrefs = this.getBrandPrefs()
      store.currentFormulary = store.formularies[store.selectedFormularyName].masks_preferences;
      this.getSettingsHistory();
      toast.success('Settings updated');

    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  // Deprecated -> preferences are now at mask level. See: this.getFormulary()
  // submitBrandPreferences = async () => {
  //   store.loading = true;
  //   console.log('Saving brand preferences: ', store.brandPrefs);
  //   try {
  //     await sendPostRequest(
  //       '/rest/administrator/brands',
  //       store.brandPrefs,
  //     );
  //     this.getSettingsHistory();
  //     toast.success('Settings updated');
  //   } catch (err) {
  //     this.handleError(err);
  //   }
  //   store.loading = false;
  // };
  //(brands)

  getBrandPrefs = () => {
    // Get mask preferences and brands
    const maskPreferences = store.formularies[store.selectedFormularyName].masks_preferences;

    const masks = maskPreferences.reduce((result, pref) => {
      let mask_ = store.maskData[pref.mask_id]
      if (store.currentSubdomain.allowed_mfrs?.includes(mask_?.company)) {
        result.push(
          {
            name: mask_.name,
            brand: mask_.company,
            score: pref.preference_score
          }
        );
      }
      return result;
    }, []);

    // Preference score sum by brand
    const BrandPreference = {};
    masks.forEach(mask => {
      const { name, brand, score } = mask;

      if (BrandPreference[brand] === undefined) {
        BrandPreference[brand] = { totalScore: 0 };
      }
      BrandPreference[brand].totalScore += score;
    });

    // Avg preference score
    const brandPreferences = {};
    for (const category in BrandPreference) {
      const totalScore = BrandPreference[category].totalScore;
      brandPreferences[category] = totalScore / masks.length;
    }

    return brandPreferences;

  }

  // Deprecated -> preferences are now at mask level. See: this.getFormulary()
  // getBrands = async (cancelToken = null) => {
  //   store.loading = true;
  //   try {
  //     const url = '/rest/administrator/brands'
  //     const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
  //     const { data } = resp.data;
  //     Object.keys(data).forEach((key) => {
  //       let value = data[key];
  //       // Filter out non-allowed manufacturers
  //       if (store?.currentSubdomain?.allowed_mfrs?.includes(key)) {
  //         data[key] = value;
  //       } else {
  //         data[key] = 0;
  //       }
  //     });
  //     store.brandPrefs = data;
  //     this.buildMfrPrefsChart();
  //   } catch (err) {
  //     this.handleError(err);
  //   }
  //   store.loading = false;
  // };

  buildMfrPrefsChart = () => {
    const mfrData = [['Manufacturer', 'Preferences']];
    for (let brand in store.simulatedBrandPercentages) {
      const value = store.simulatedBrandPercentages[brand]
      if (value > 0) {
        mfrData.push([brand, value]);
      }
      store.mfrPrefs = mfrData;
    }
  }
  getBlacklistedEmails = async () => {
    store.loading = true;
    try {
      const { data } = await sendGetRequest('/rest/get_email_blacklist');
      store.blacklistedEmails = data.emails;
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  getSettingsHistory = async (cancelToken = null) => {
    store.loading = true;
    try {
      const response = await sendGetRequest('/rest/settings_history', { cancelToken: cancelToken?.token });
      store.settingsHistory = response.data.data;
    } catch (error) {
      this.handleError(error);
    }
    store.loading = false;
  }

  forgetPassword = async (data) => {
    const options = {
      email: data.email,
      branches: data.branches
    }
    store.loading = true;
    try {
      let resp = await axios.post('/rest/user/forgotpassword', options)
      // window.location.href = '/user/email'
      toast.success('Password Reset Email Sent!')
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  resetPassword = async (data) => {
    const queryString = window.location.search;
    const options = {
      password: data.newpassword,
      confirm_password: data.confirm,
    };
    const urlParams = new URLSearchParams(queryString);
    options['token'] = urlParams.get('token');
    store.loading = true;
    try {
      await axios.post('/rest/user/resetpassword', options);
      toast.success('Password reset');
      setTimeout(this.logout, 1000);
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  changePassword = async (data) => {
    store.changePasswordLoading = true;
    const options = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
      confirm_password: data.newPasswordConfirm,
    };
    try {
      await sendPostRequest(
        '/rest/user/changepassword',
        options,
      );
      toast.success('Password changed');
      store.changePasswordLoading = false;
      return true;
    } catch (err) {
      err.ignore403 = true;
      this.handleError(err);
      store.changePasswordLoading = false;
      return false;
    }
  };

  closeDetails = async () => {
    if (
      store?.selectedRow?.status?.toLowerCase()?.includes('processed') &&
      !store.loading &&
      !store.patientCompleteLoading // Pt is scanned and not being currently completed
    ) {
      store.showPopUpToCompletePt = true
    } else {
      store.showPopUpToCompletePt = false
      this.closePatientDetailsModal()
    }
  }

  closePatientDetailsModal = () => {
    appStore.changeActiveMasksTab('recommended')
    store.selectedRow = {};
    store.patientAlerts = [];
    localStorage.removeItem('selectedRow');

    if (store?.patientChatSocketOpen) {
      PatientChatSocketInstance.close()
      store.patientChatSocketOpen = false
    }
  }

  selectUser = async (data) => {
    store.selectedUser = data;
  };

  unSelectUser = async () => {
    store.selectedUser = '';
  };

  administratorResetPassword = async (data) => {
    store.loading = true;

    const payload = {
      username: store.selectedUser.username,
      password: data.userPass,
      confirmPassword: data.userConfirm,
    };

    try {
      await sendPostRequest(
        '/rest/administrator/resetpassword',
        payload,
      );
      toast.success('Password reset');
      this.unSelectUser();
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  twilioLookupPhone = async (phoneNumber, noAuth = false) => {
    store.twilioLookupLoading = true;
    try {
      let resp = await sendPostRequest('/rest/twilio_lookup_phone', { phoneNumber, noAuth });
      store.twilioLookupLoading = false;
      return {
        numberLookupStatus: resp.data.status,
        numberLookupCode: resp.data.code,
        numberIsSMSReachable: resp.data.is_sms_reachable,
      }
    } catch (err) {
      if (!noAuth) this.handleError(err)
      else {
        if (err.response && err.response.data && err.response.data.error) {
          console.log('ERROR: ', err.response.data.error);
        } else {
          console.log('ERROR: ', err)
        }
      }
    }
    store.twilioLookupLoading = false;
  }

  addPatient = async (data) => {
    store.loading = true;

    const patientData = {
      patient_subdomain: data.subdomain,
      patient_email: data.email,
      patient_phone: data.number,
      patient_id: data.id,
      invite_channel: data.inviteChannel,
      isRecall: data.isRecall,
      override_brands: data.overrideBrands,
      override_types: data.overrideTypes,
      clinic_notes: data.notes,
      ahi: parseInt(data.ahi, 10),
      pressure: data.pressure,
      pap_therapy: data.pap_therapy,
      bmi: data.bmi,
      ethnicity: data.ethnicity,
      isScripted: data.isScripted,
      sleepStudyType: data.sleepStudyType,
      is_mobile: data.isMobile,
      birthDate: data.birthDate,
      firstname: data.firstName,
      lastname: data.lastName,
      location: data.location,
      language: data.language,
      setUpDate: data.setUpDate,
      patientAssignedTo: data.patientAssignedTo,
      is_first_time_cpap: data.isFirstTime,
      formulary_name: data.formularyName,
      formulary_mask_list: data.formularyMaskList,
      masksPreferences: data.masksPreferences || {},
      referring_npi: data.referringPhysicianNumber,
      physician_subdomain: data.physicianSubdomain,
      referring_name: data.referringPhysicianFullName,
      facility_type: data.facilityType,
      allow_duplicate_id: data.allowDuplicate,
      shared_with: data.sharedWith || [],
      refit_answers: (data.prescribed_mask) ?
        {
          "answers": {
            "switch_reason": "prescription",
            "switched_to_mask_size": data.prescribed_mask_cushion_size,
            "switched_to_mask_type": data.prescribed_mask,
            "switched_to_mask_frame_size": data.prescribed_mask_frame_size,
            ...(data.prescribed_mask_other !== null && { "switched_to_mask_type_other": data.prescribed_mask_other }),
          },
          "version": refitQuestions.version
        } : ''
    };

    let result = await sendPostRequest('/rest/patient', patientData);
    // Redirect dialog for Tablet Mode
    if (data.inviteChannel === 'hold') {
      store.inPersonModePatient = {
        'inviteUrl': result.data.url,
        'websiteToken': result.data.website_token
      };
      this.setShowInPersonModal(true);
    }

    toast.success('Patient added');

    store.loading = false;
  };

  // * checkExistingPatient returns patient with same ID exist or not
  checkExistingPatient = async (data) => {
    const patientData = {
      patient_subdomain: data.subdomain,
      patient_id: data.id
    };

    return await sendPostRequest('/rest/check_existing_patient', patientData);
  };
  // * checkExistingRefit returns refit with same Mask
  checkExistingRefit = async (mask) => {
    const refitData = {
      patient_subdomain: store.selectedRow.subdomain,
      website_token: store.selectedRow.website_token,
      mask_switched_to: mask
    };
    return await sendPostRequest('/rest/check_existing_refit', refitData);
  };


  downloadAlertAsCSVLink = () => {
    var url = '/rest/alert_as_csv?token=' + readCookieValue('token');
    if (store.alertFilters.locations.length > 0) {
      url += '&locations=' + store.alertFilters.locations.join(';');
    }
    if (store.alertFilters.patientId) {
      url += '&patient_id=' + store.alertFilters.patientId;
    }
    if (store.alertFilters.startDate) {
      url += '&start_date=' + formatDateToString(store.alertFilters.startDate);
    }
    if (store.alertFilters.endDate) {
      url += '&end_date=' + formatDateToString(store.alertFilters.endDate);
    }
    if (store.alertFilters.resolvedBy.length > 0) {
      url += '&resolved_by=' + store.alertFilters.resolvedBy.join(';');
    }
    if (store.alertFilters.createdBy.length > 0) {
      url += '&created_by=' + store.alertFilters.createdBy.join(';');
    }
    if (store.alertFilters.assignedTo.length > 0) {
      url += '&created_by=' + store.alertFilters.assignedTo.join(';');
    }
    if (store.alertFilters.alertType.length > 0) {
      url += '&alert_type=' + store.alertFilters.alertType.join(';');
    }
    if (store.alertFilters.status != null && store.alertFilters.status.length > 0) {
      url += '&alert_status=' + store.alertFilters.status.join(';');
    }
    return url;
  }

  setPatientsStatus = (patientList) => {
    store.patientStatuses = patientList.map(pt => {
      return ({
        website_token: pt?.website_token || '',
        status: pt?.status || '',
        mask: pt?.current_mask_type || pt?.currentMaskType,
        mask_size: pt?.current_mask_size || pt?.currentMaskSize,
        fitting_way: pt?.fitting_way || pt?.fittingWay,
      })
    })
  }

  loadPatientsStatus = async (type) => {
    try {
      const url = '/rest/patient_status'
      const resp = await sendPostRequest(url, store.patientStatuses);
      this.updatePatientsStatus(resp?.data?.changed_statuses);
    } catch (error) {
      this.handleError(error);
    }
  }

  loadScanCounter = async () => {
    try {
      let resp = await sendGetRequest('/rest/scan_counter');
      store.scanPackageEnabled = resp.data.scan_package_enabled;
      store.remainingScans = resp.data.remaining_scans;
    } catch (error) {
      this.handleError(error);
    }
  }

  setPatientNotes = (newNotes, count) => {
    store.patient_shared_notes = newNotes.map((psn) => { return { ...psn, expend: false } });
    store.notesTotalRows = count;
  }

  setFilterStatus = (store_object, filter_attribute, statusArray) => {
    store[store_object][filter_attribute] = statusArray
  }


  updatePatientNotificationCount = (res) => {
    const completedPatients = isValidArray(res) && res.filter((status) => status?.status === 'Completed' || status?.status === 'RX - Completed');
    if (!document.hasFocus() && completedPatients?.length > 0) {
      store.patientNotificationCount = store.patientNotificationCount + completedPatients?.length;
      document.title = `SleepGlad (${store.patientNotificationCount} new update)`;
    }
    this.setFavicon('favicon-alert.ico');
  }

  resetPatientNotificationCount = () => {
    store.patientNotificationCount = 0;
    document.title = "SleepGlad";
    this.setFavicon('favicon.ico');
  }

  updatePatientStatus = (newStatus) => {
    const index = store.patients.findIndex((il) => il.website_token === newStatus.website_token)
    if (index >= 0) {
      const allData = {
        ...store.patients[index], status: newStatus.status,
        currentMaskType: newStatus.mask,
        currentMaskSize: newStatus.mask_size,
        fittingWay: newStatus.fitting_way
      }
      const updatedList = update(store.patients, { $splice: [[index, 1, allData]] });
      store.patients = updatedList;
    }
  }

  updatePatientsStatus = (res) => {
    let patientList = cloneDeep(store.patients);
    if (isValidArray(res)) {
      for (const status of res) {
        const index = patientList.findIndex(x => x.website_token === status.website_token);
        patientList[index] = {
          ...patientList[index],
          status: status.status,
          currentMaskType: status.mask,
          currentMaskSize: status.mask_size,
          fittingWay: status.fitting_way
        }
      }
      this.setPatientsStatus(patientList);
      this.replacePatientList(patientList);
      this.updatePatientNotificationCount(res)
    }
  }

  sendStaffMessage = async () => {
    //send post request to 'rest/patient_messages_now'
    try {
      const resp = await sendPostRequest('/rest/patient_messages_now', {});
    }
    catch (error) {
      console.log('error on sending staff message', error);
    }
  }

  loadPatients = async (incrementalUpdate = null, cancelToken = null) => {
    if (!incrementalUpdate) {
      store.loading = true;
    }
    try {
      const url = this.buildPatientListUrl(incrementalUpdate, false);
      if (incrementalUpdate && store.lastLoadPatientsTimestamp) {
        store.lastLoadPatientsTimestamp = Date.now() / 1000;
      } else {
        // This is a full update to the current time
        store.lastLoadPatientsTimestamp = Date.now() / 1000;
      }
      const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      var patientList = this.parsePatientList(resp.data.data);
      store.patientsTotalRows = resp.data.totalRows;
      if (resp.data.data.length) this.setPatientsStatus(resp.data.data);
      if (incrementalUpdate) {
        patientList = this.mergePatients(store.initialPatients, patientList);
      }
      this.replacePatientList(patientList);
      if (!patientList.length) store.hasPatients = true;
      if (!patientList.length > 0) store.hasPatients = false;

    } catch (err) {
      if (incrementalUpdate) {
        console.log('Error during background request: ', err);
      } else {
        this.handleError(err);
      }
    }
    store.loading = false;
  };

  buildPatientListUrl = (incrementalUpdate, csv) => {
    let url = '/rest/patient_list/' + store.currentUser.customerSubdomain
    if (csv) {
      url += '?csv=true'
    }
    else {
      url += '?max_count=50';
      url += '&first_row_num=' + 50 * (store.patientsPageNumber - 1);
    }
    if (store.filters.status && store.filters.status.length > 0) {
      url += '&status=' + store.filters.status.join(';');
    }
    if (store.filters.facilityType && store.filters.facilityType.length > 0) {
      url += '&facility_type=' + store.filters.facilityType.join(';');
    }
    if (store.filters.invitationMode && store.filters.invitationMode.length > 0) {
      url += '&invite_channel=' + store.filters.invitationMode.join(';');
    }
    if (store.filters.locations && store.filters.locations.length > 0) {
      url += '&locations=' + store.filters.locations.join(';');
    }
    if (store.filters.regions && store.filters.regions.length > 0) {
      url += '&regions=' + store.filters.regions.join(';');
    }
    if (store.filters.customers && store.filters.customers.length > 0) {
      url += '&customers=' + store.filters.customers.join(';');
    }
    if (store.filters.nameSearch) {
      store.filters.nameSearch = store.filters.nameSearch.replace("+", "%2B");
      url += '&search_string=' + store.filters.nameSearch;
    }
    if (store.filters.startDate) {
      url += '&start_date=' + dayjs(store.filters.startDate).format('MM-DD-YYYY');
    }
    if (store.filters.endDate) {
      url += '&end_date=' + dayjs(store.filters.endDate).format('MM-DD-YYYY');
    }
    if (store.filters.userEmail && store.filters.userEmail.length > 0) {
      url += '&user_email=' + store.filters.userEmail
        .join(';').replaceAll('+', '%2B');
    }
    if (store.filters.physician) {
      url += '&physician=' + store.filters.physician;
    }

    if (store.newPatientFilter) {
      url += '&is_new_patient=' + store.newPatientFilter;
    }
    if (store.filters.internalPatients) {
      url += '&internal_patients=' + store.filters.internalPatients;
    }
    if (store.filters.resupplyPatients) {
      url += '&is_new_patient=' + false;
    }
    if (store.filters.sharedToPatients) {
      url += '&is_received=' + store.filters.sharedToPatients;
    }
    if (store.filters.sharedFromPatients) {
      url += '&shared_from=' + store.filters.sharedFromPatients;
    }
    if (store.filters.dateType) {
      url += '&date_type=' + store.filters.dateType;
    }
    if (store.filters.recallPtsOnly) {
      url += '&campaign_patients_only=' + true;
    }
    if (store.filters.recallPtsYes != null && store.filters.recallPtsYes != 'undefined') {
      url += '&recall_pts_yes=' + store.filters.recallPtsYes;
    }
    if (store.filters.recallPtNotesExists !== '') {
      url += '&recall_pt_notes_exists=' + store.filters.recallPtNotesExists
    }
    if (store.filters.recallPtNotesSearch) {
      url += '&search_recall_pt_notes_string=' + store.filters.recallPtNotesSearch
    }
    if (store.filters.patientAssignedTo && store.filters.patientAssignedTo.length > 0) {
      url += '&patientAssignedTo=' + store.filters.patientAssignedTo
        .join(';').replaceAll('+', '%2B');
    }
    if (store.filters.wasRefit !== '') {
      url += '&was_refit=' + store.filters.wasRefit
    }

    if (store.sortBy) url += `&sort_by=${store.sortBy}`

    if (incrementalUpdate && store.lastLoadPatientsTimestamp) {
      // Only fetch what has changed (plus 10 seconds overlap)
      url += '&since=' + (store.lastLoadPatientsTimestamp - 10);
    }

    return url;
  };

  parsePatientList = (data) => {
    let newPatients = [];
    data.forEach((element, index) => {
      newPatients.push({
        website_token: element.website_token,
        subdomain: element.subdomain,
        createdTimeFormatted: element.time_formatted || 'N/A',
        timezone: element.timezone,
        createdAt: element.created_at,
        createdBy: element.created_by || 'N/A',
        patientId: element.patient_id,
        firstName: element.last_name,
        lastName: element.first_name,
        fullName: `${element.last_name}, ${element.first_name}`,
        status: element.status || 'N/A',
        isMarkedComplete: element.is_marked_complete,
        location: element.location,
        location_short_name: element.location_short_name,
        currentMaskType: element.current_mask_type,
        currentMaskTypeOther: element.current_mask_type_other,
        currentMaskSize: element.current_mask_size,
        fittingWay: element.fitting_way,
        inviteChannel: element.invite_channel,
      });
    });
    return newPatients;
  };

  mergePatients = (existingPatientList, updatedPatientList) => {
    // Both existingPatientList and newPatientList are ORDER BY created_at DESC
    // Replace any row in oldPatientList that is included in newPatientList
    var i = 0;
    var j = 0;
    while (i < existingPatientList.length && j < updatedPatientList.length) {
      var oldPatient = existingPatientList[i];
      var newPatient = updatedPatientList[j];
      if (oldPatient.createdAt === newPatient.createdAt && oldPatient.patientId === newPatient.patientId) {
        // Updating a new patient: check if this is a new Processed scan
        if (existingPatientList[i].status != "Processed" && updatedPatientList[j].status === "Processed") {
          this.displayNotificationIndicator();
        }
        existingPatientList[i] = updatedPatientList[j];
        i++;
        j++;
      } else if (oldPatient.createdAt < newPatient.createdAt) {
        j++;
      } else {
        i++;
      }
    }
    return existingPatientList;
  }

  displayNotificationIndicator() {
    if (!document.hasFocus()) {
      document.title = "SleepGlad (1 new update)";
    }
    this.setFavicon('favicon-alert.ico');
  }

  resetNotificationIndicator() {
    document.title = "SleepGlad";
    this.setFavicon('favicon.ico');
  }

  setFavicon(url) {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = url;
  }

  replacePatientList = (newPatients) => {
    store.initialPatients = cloneDeep(newPatients);
    store.patients = newPatients;
    store.statuses = ['Waiting', 'Processed', 'Delivered', 'Failed', 'Scan Error', 'Expired', 'Recall - Completed', 'Completed', 'Voided', 'RX', 'RX - Processed', 'RX - Completed']
  }

  showPatientDetails = async (data) => {
    await this.loadPatientDetails(data.website_token);
    localStorage.setItem('selectedRow', JSON.stringify(store.selectedRow));
  };

  loadPatientDetails = async (website_token) => {
    store.loading = true;
    try {
      var url = '/rest/patient_details/' + website_token;
      const resp = await sendGetRequest(url);
      const { data } = resp.data;
      const refits = [];
      data.refits.forEach((element) => {
        const refit = element.refit_details.answers;
        refit.submitted_at = patientDetailDate(element.submitted_at);
        refit.submitted_by = element.submitted_by;
        refit.type = 'Refit';
        if (refit.switch_reason === 'technician_selection') {
          refit.type = 'Selection';
        } else if (refit.switch_reason == 'prescription') {
          refit.type = 'Prescription';
        } else if (refit.switch_reason == 'switched_from_prescription') {
          refit.type = 'Switched from prescription';
        } else if (refit.switch_reason == 'recommendation') {
          refit.type = 'Recommendation';
        } else if (refit.switch_reason == 'switch_from_recommendation') {
          refit.type = 'Switch From Recommendation';
        }
        refits.push(refit);
      });
      const patientDetails = {
        "website_token": data.website_token,
        "subdomain": data.subdomain,
        "timestamp": data.timestamp,
        "customerTimezone": data.timezone,
        "locationTimezone": data.location_timezone,
        "created": data.time_formatted,
        "patientId": data.patient_id,
        "first_name": data.first_name,
        "last_name": data.last_name,
        "url": data.url,
        "model_output": data.model_output,
        "status": data.status,
        "notes": data.clinic_notes,
        "surveyNotes": data.survey_notes,
        "phone": data.phone,
        "email": data.email,
        "created_by": data.created_by,
        "created_at": data.created_at,
        "processed_at": data.processed_at,
        "created_at_timestamp": data.created_at_timestamp,
        "completed_by": data.completed_by,
        "voided_at": data.voided_at,
        "voided_by": data.voided_by,
        "void_reason": data.void_reason,
        "message_to_patient": data.message_to_patient,
        "ahi": data.ahi,
        "pressure": data.pressure,
        "pap_therapy": data.pap_therapy,
        "complete": data.is_marked_complete,
        "is_marked_complete": data.is_marked_complete,
        "location": data.location,
        "locationId": data.location_id,
        "location_npi_number": data.npi_number,
        "location_with_name_formatted": data.location_with_name_formatted,
        "refits": refits,
        "current_mask_type": data.current_mask_type,
        "current_mask_size": data.current_mask_size,
        "current_mask_frame_size": data.current_mask_frame_size,
        "patient_attributes": data.patient_attributes,
        "formularyName": data.formulary_name,
        "formulary": data.formulary,
        "outbox_messages": data.outbox_messages,
        "show_patient_results": data.show_patient_results,
        "send_message_on_complete": data.send_message_on_complete,
        "language": data.language,
        'date_of_birth': data.date_of_birth,
        'ethnicity': data.ethnicity,
        'bmi': data.bmi,
        'sleep_study_type': data.sleep_study_type,
        'is_scripted': data.is_scripted,
        'is_first_time_cpap': data.is_first_time_cpap,
        'referring_npi': data.referring_npi,
        'survey_answers': data.survey_answers,
        'is_shared': data.is_shared,
        'shared_with': data.shared_with,
        'shared_notes': data.shared_notes,
        'patient_details_history': data.patient_details_history,
        "date_of_service": data.date_of_service,
        'referring_name': data.referring_name,
        'before_recall_attributes': data.before_recall_attributes,
        'before_recall_attributes_processed_at': data.before_recall_attributes_processed_at,
        'onboarded_by': data.onboarded_by,
        'status_history': data.status_history,
        'nro_enabled': data.nro_enabled,
        'isCompliant': data.is_compliant,
        'compliancePercentage': data.compliance_percentage,
        'complianceDay': data.compliance_day,
        'complianceMachine': data.compliance_machine,
        'compliance_data': data.compliance_data,
        'compliance_orders': data.compliance_orders,
        'reacthealth_compliance_data': data.reacthealth_compliance_data,
      };
      store.selectedRow = cloneDeep(patientDetails);
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  };

  changeActiveTab = (tabName) => {
    store.activeTab = tabName;
    if (!store.users?.length) {
      switch (tabName) {
        case 'alerts':
          this.loadUsers({ is_active: true })
          break
      }
    }
  }

  changeActiveMasksTab = (tabName) => {
    store.activeMasksTab = tabName;
  }

  createRecallPatientNotes = async (payload) => {
    try {
      const { data } = await sendPostRequest('/rest/recall_pt_notes', payload)
      store.recallPatientNotes = data.notes
      //this.loadRecallPatientNotes(payload.websiteToken)
    } catch (err) {
      this.handleError(err)
    }
  }

  loadRecallPatientNotes = async (websiteToken) => {
    try {
      const { data } = await sendGetRequest(`/rest/recall_pt_notes?websiteToken=${websiteToken}`)
      store.recallPatientNotes = data.notes
    } catch (err) {
      this.handleError(err)
    }
  }

  loadUsers = async (filters = {}, cancelToken = null) => {
    store.usersLoading = true;
    try {
      store.loading = true;
      let url = '/rest/users';
      // fromDashboard arg. is to get less (just enough) data
      url += '?fromDashboard=true'
      if (filters.is_active) url += '&activeOnly=true'
      if (store.usersSortedBy) url += `&sort_by=${store.usersSortedBy}`
      const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      const { users } = resp.data;
      store.users = users
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
    store.usersLoading = false;
  };

  loadAllUsers = async (filters = {}, cancelToken = null) => {
    try {
      let url = '/rest/users?fromDashboard=false&max_count=50';
      url += `&first_row_num=${50 * (store.usersPageNumber - 1)}`

      if (filters.userStatus) url += `&user_status=${filters.userStatus}`;
      if (filters.is_active) url += `&activeOnly=true`;
      if (filters.role && filters.role !== 'all') url += `&role=${filters.role}`;
      if (filters.location && filters.location !== 'all') url += `&branch=${filters.location}`;
      if (filters.search) url += `&searchString=${filters.search}`;
      if (filters.loginDateFrom && filters.loginDateTo) {
        url += `&loginDateFrom=${dayjs(filters.loginDateFrom).format('MM-DD-YYYY')}&loginDateTo=${dayjs(filters.loginDateTo).format('MM-DD-YYYY')}`
      }
      if (store.usersSortedBy) url += `&sort_by=${store.usersSortedBy}`
      const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      const { users, row_count } = resp.data;
      store.usersTotalRows = row_count;
      return users;
    } catch (error) {
      this.handleError(error)
    }
  }

  loadScriptedPhysicians = async (cancelToken = null) => {
    const token = readCookieValue('token')
    store.scriptedPhysiciansLoading = true;
    try {
      store.loading = true;
      let url = `/rest/get_scripted_physicians?token=${token}&customer_name=${subdomain}`;
      const resp = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.scriptedPhysicians = resp.data;
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
    store.scriptedPhysiciansLoading = false;
  };

  getUsersCSV = (filters = {}) => {
    try {
      let url = '/rest/users?fromDashboard=false';
      // let url = '/rest/users?fromDashboard=false&max_count=50';
      // url += `&first_row_num=${50 * (store.usersPageNumber - 1)}`

      if (filters.userStatus) url += `&user_status=${filters.userStatus}`;
      if (filters.is_active) url += '&activeOnly=true'
      if (filters.role && filters.role !== 'all') url += `&role=${filters.role}`;
      if (filters.location && filters.location !== 'all') url += `&branch=${filters.location}`;
      if (filters.search) url += `&searchString=${filters.search}`;
      if (filters.loginDateFrom && filters.loginDateTo) {
        url += `&loginDateFrom=${dayjs(filters.loginDateFrom).format('MM-DD-YYYY')}&loginDateTo=${dayjs(filters.loginDateTo).format('MM-DD-YYYY')}`
      }
      if (store.usersSortedBy) url += `&sort_by=${store.usersSortedBy}`
      url += `&csv=true&token=${readCookieValue('token')}`

      return url
    } catch (error) {
      this.handleError(error)
    }
  }

  getUserHistory = async () => {
    try {
      const { data } = await sendGetRequest(`/rest/user_edit_history?created_for=${store.selectedUser.email}`)
      return data.userEditHistory
    } catch (err) {
      this.handleError(err)
    }
  }
  setUserHistory = async (data) => {
    try {
      await sendPostRequest('/rest/user_edit_history', data)
    } catch (err) {
      this.handleError(err)
    }
  }

  updateOnlineChatUsers = (data) => {
    const { func, online_user } = data
    // If function type is "add" AND user is not already in, then add it
    if (func === 'add' && !store.onlineChatUsers.includes(online_user)) {
      store.onlineChatUsers.push(online_user)
    }
    // If function type is "remove" AND user already exists, then remove that user
    if (func === 'remove' && store.onlineChatUsers.includes(online_user)) {
      const idx = store.onlineChatUsers.indexOf(online_user)
      if (idx !== -1) store.onlineChatUsers.splice(idx, 1)
    }
  }

  getOnlineChatUsers = async (subdomain, websiteToken) => {
    try {
      const url = `/rest/online_chat_users?subdomain=${subdomain}&website_token=${websiteToken}`
      const { data } = await sendGetRequest(url)
      store.onlineChatUsers = data.online_users
    } catch (error) {
      this.handleError(error)
    }
  }

  editData = async (data, patientType, updateType) => {
    store.editDataLoading = true;
    store.scriptedMaskLoading = updateType === 'scripted_mask';
    const website_token = store.selectedRow.website_token
    try {
      await sendPostRequest('/rest/patient/update', data);
      if (['shared_note', 'shared_with', 'patient_phone', 'should_send_sms', 'should_send_email', 'scripted_mask', 'patient_email'].includes(updateType)) {
        {/* Reload patient's details for the above updateType after Edition, calling the API to refresh data (i.e. the history tab) */ }
        this.loadPatientDetails(website_token);
      } else if (patientType === 'pressure' && updateType === 'pap_therapy') {
        //for pressure and pap therapy fields, we use patientType and updateType
        //to update both of them with one call.
        store.selectedRow[patientType] = data[patientType];
        store.selectedRow[updateType] = data[updateType];
        this.loadPatientDetails(website_token);
      } else if (patientType === 'location') {
        store.selectedRow.location = data.locationLabel
        store.selectedRow.locationId = data.location
        store.selectedRow.subdomain = data.subdomain
      }
      else {
        store.selectedRow[patientType] = data[updateType];
      }
      toast.success('Patient updated');
    } catch (err) {
      this.handleError(err)
    }
    store.editDataLoading = false;
    store.scriptedMaskLoading = false;
  };

  resendResults = async message_type => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/patient/results', {
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
      })
      this.closeDetails();
      this.loadPatients();
      toast.success('Message Sent');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  }

  markCompleteWithMessage = async (date_of_service, isOtherMask = false, isScripted = false, shouldSendRhIntegration = null) => {
    store.loading = true;
    try {
      var should_send_message = this.shouldSendMessageOnComplete();
      const payload = {
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
        mark_as_complete: true,
        completed_by: store.currentUser.username,
        mark_as_scripted: isScripted,
        should_send_message: (store.selectedRow.is_scripted || isScripted) ? false : should_send_message,
        date_of_service: date_of_service,
      }
      if (store.currentSubdomain.isRhParticipant) {
        payload.should_send_rh = shouldSendRhIntegration === true ? true : false
      }
      await sendPostRequest('/rest/patient/complete', payload);
      this.closeDetails();
      this.loadPatients();
      if (!isOtherMask) toast.success('Patient marked complete');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  saveUserIncompletions = async (website_token) => {
    try {
      await sendPostRequest('/rest/save_user_incompletions', { website_token: website_token });
    } catch (err) {
      this.handleError(err)
    }
  }

  markPatientVoid = async (void_reason) => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/patient/void', {
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
        voided: true,
        voided_by: store.currentUser.username,
        void_reason: void_reason
      });
      this.closeDetails();
      this.loadPatients();
      toast.success('Patient Setup Cancelled');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  }

  shouldSendMessageOnComplete = (selectedType) => {
    // Special case: If the patient already saw their mask AND their mask has not changed
    // if (store.selectedRow.show_patient_results && selectedType == store.selectedRow.current_mask_type) {
    //  return false;
    // }
    // Otherwise, send a message iff SendMessageOnComplete is enabled

    // Patient is unsusbscribed
    let unsubscribed = this.patientIsUnsubscribed();
    if (unsubscribed) {
      return false;
    }

    return !!store.selectedRow.send_message_on_complete;
  };

  patientIsUnsubscribed = () => {
    let unsubscribed = false;
    if (
      (store.selectedRow.patient_attributes?.email_unsubscribed_at && store.selectedRow.patient_attributes.invite_channel === "invite_email") ||
      (store.selectedRow.patient_attributes?.sms_unsubscribed_at && store.selectedRow.patient_attributes.invite_channel === "invite_text")
    ) {
      unsubscribed = true;
    }
    return unsubscribed;
  }


  reInvite = async () => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/patient/reinvite', {
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
      });
      this.closeDetails();
      this.loadPatients();
      toast.success('Patient re-invited');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  reprocessPatient = async (payload) => {
    try {
      store.reprocessPatientLoading = true
      const { data: { model_output, patient_attributes } } = await sendPostRequest('/rest/reprocess_patient', payload)
      store.selectedRow.patient_attributes = {
        ...store.selectedRow.patient_attributes,
        ...patient_attributes
      }
      store.selectedRow.model_output = model_output
      store.selectedRow.bmi = patient_attributes.bmi
    } catch (error) {
      this.handleError(error)
    }
    store.reprocessPatientLoading = false
  }

  resendRecallInvite = async () => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/reset_recall', {
        website_token: store.selectedRow.website_token,
      });
      this.loadPatients();
      toast.success('Patient recall campaign resent');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  }

  markPtRecallComplete = async (magnet_sensitive_implants = false) => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/recall_complete', {
        website_token: store.selectedRow.website_token,
        magnet_sensitive_implants: magnet_sensitive_implants,
      });
      this.closeDetails();
      this.loadPatients();
      toast.success('Marked patient as Recall Complete');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  }

  submitRefitFeedback = async (refitAnswers, refitVersion) => {
    store.loading = true;
    try {
      refitAnswers['switch_reason'] = store.selectedRow?.is_scripted ? 'switched_from_prescription' : 'refit';
      let data = {
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
        refit_details: {
          version: refitVersion,
          answers: refitAnswers
        }
      };
      await sendPostRequest('/rest/refitfeedback', data);
      this.closeDetails();
      this.loadPatients();
      toast.success('Refit submitted');
      //console.log("RefitData: " + JSON.stringify(data));
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  submitNonRecommendedFeedback = async (refitAnswers, refitVersion) => {
    store.loading = true;
    try {
      refitAnswers['switch_reason'] = 'non-recommended-opt';
      let data = {
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
        refit_details: {
          version: refitVersion,
          answers: refitAnswers
        }
      };
      await sendPostRequest('/rest/refitfeedback', data);
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  checkInRemainingMasks = (maskName) => {
    let remiaing_masks_list = []
    if (store.selectedRow
      && store.selectedRow.model_output
      && store.selectedRow.model_output.rest_of_formulary_mask_results
      && store.selectedRow.model_output.oof_mask_results) {
      remiaing_masks_list = store.selectedRow.model_output.rest_of_formulary_mask_results.concat(store.selectedRow.model_output.oof_mask_results)
    }

    for (let i = 0; i < remiaing_masks_list.length; i++) {
      if (remiaing_masks_list[i].name === maskName) {
        return true
      }
    }

    return false
  }
  submitClinicianSelection = async (website_token, patient_subdomain, mask_name, mask_cushion_size, mask_frame_size, refits_version, switch_reason, chin_strap_dispensed, prescribed_mask_other = null) => {
    store.patientCompleteLoading = true;
    try {
      let data = {
        website_token: website_token,
        patient_subdomain: patient_subdomain,
        refit_details: {
          version: refits_version,
          answers: {
            switch_reason: this.checkInRemainingMasks(mask_name) ? 'switch_from_recommendation' : switch_reason,
            switched_to_mask_type: mask_name,
            switched_to_mask_size: mask_cushion_size,
            switched_to_mask_frame_size: mask_frame_size,
            chin_strap_dispensed: chin_strap_dispensed,
            ...(prescribed_mask_other !== null && { switched_to_mask_type_other: prescribed_mask_other }),
          }
        },
      };
      await sendPostRequest('/rest/select_recommendation', data);

    } catch (err) {
      this.handleError(err)
    }
    store.patientCompleteLoading = false;
  };

  addUser = async (data) => {
    store.loading = true;
    try {
      const userData = {
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName,
        phone: data.phoneNumber,
        password: data.password,
        super_administrator: data.role === 'super_administrator' ? true : false,
        administrator: data.role === 'administrator' ? true : false,
        manager: data.role === 'manager' ? true : false,
        clinician: data.role === 'clinician' ? true : false,
        readonly: data.role === 'readonly' ? true : false,
        branches: data.branches,
        should_send_email: data.shouldSendEmail,
        is_doctor: data.isDoctor
      };
      await sendPostRequest('/rest/user', userData);
      toast.success('User added');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  editUser = async () => {
    store.loading = true;
    try {
      const { email, oldEmail, firstName, lastName, phoneNumber, branches, role } = store.selectedUser;
      const userData = {
        email,
        username: email,
        oldEmail: oldEmail || null,
        firstName: firstName || null,
        lastName: lastName || null,
        phoneNumber: phoneNumber || null,
        branches: branches || null,
        role: role || null
      };
      await sendPostRequest('/rest/user/edit', userData);
      toast.success('User saved');
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  setUserDoctor = async (data) => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/user/doctor', data);
      if (store.currentUser.username === data.username) {
        this.initializeCurrentUser();
      }
      this.setUserHistory({
        created_for: data.username,
        action: [`${store.currentUser.username} set the user as ${data.is_doctor ? 'prescriber' : 'non-prescriber'}.`]
      })
      toast.success('Updated user ' + data.username);
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  setUserActive = async (data) => {
    store.loading = true;
    try {
      const userData = {
        username: data.username,
        is_active: data.isActive,
      };
      await sendPostRequest('/rest/user/active', userData);
      this.setUserHistory({
        created_for: data.username,
        action: [`${store.currentUser.username} ${data.isActive ? 'activated' : 'deactivated'} the user.`]
      })
      toast.success('Updated user ' + data.username);
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  saveLocationDetails = async () => {
    store.loading = true;
    try {
      const locationData = {
        id: store.selectedLocationId,
        short_name: store.selectedLocationShortName,
        dba_name: store.selectedLocationDBAName,
        timezone: store.selectedLocationTimezone.value,
        new_patient_formulary: store.selectedLocationNewPatientFormularyName,
        resupply_formulary: store.selectedLocationResupplyFormularyName,
        branch_number: store.selectedLocationBranchNumber,
        npi_number: store.selectedLocationNPINumber,
        phone_number: store.selectedLocationPhoneNumber,
        address: store.selectedLocationAddress,
      };

      await sendPostRequest('/rest/location', locationData);
      this.getLocations().then(() => this.getLocationOptions());
      toast.success('Updated location ' + locationData.short_name);
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  selfDemote = (switchRoleTo) => {
    const admins = ['manager', 'administrator', 'super_administrator']
    const regulars = ["readonly", "tech", "clinician"]
    if (admins.includes(store.currentUser.userRole) && regulars.includes(switchRoleTo)) {
      this.initializeCurrentUser()
    }
  }

  setUserRole = async (data) => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/user/role', data);
      if (data.username === store.currentUser.username) {
        this.selfDemote(data.new_role);
      }
      this.setUserHistory({
        created_for: data.username,
        action: [`${store.currentUser.username} set role to ${data.new_role} which was previously ${data.old_role}.`]
      })
      toast.success('Changed user role for ' + data.username);
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  setUserBranches = async (data) => {
    const { username, branches, action } = data;
    store.loading = true;
    try {
      await sendPostRequest('/rest/user/branches', { username, branches });
      this.setUserHistory({
        created_for: username,
        action: [action]
      })
      toast.success('Changed user ' + username);
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  lookupHealthCareProvider = async (lookupParams) => {
    store.loading = true;
    var url = '/rest/lookup/health_care_provider?'
    if (lookupParams.npiNumber) {
      url += 'npi_number=' + lookupParams.npiNumber + '&';
    }
    if (lookupParams.firstName) {
      url += 'first_name=' + lookupParams.firstName + '&';
    }
    if (lookupParams.lastName) {
      url += 'last_name=' + lookupParams.lastName + '&';
    }
    if (lookupParams.city) {
      url += 'city=' + lookupParams.city + '&';
    }
    if (lookupParams.state) {
      url += 'state=' + lookupParams.state + '&';
    }
    // if (lookupParams.postalCode) {
    //   url += 'postal_code=' + lookupParams.postal_code + '&';
    // }

    let lookupResults = {};
    try {
      let resp = await sendGetRequest(url,);
      const { data } = resp.data;
      lookupResults.message = data.message;
      switch (data.message_type) {
        case 'info':
          lookupResults.messageType = 'primary';
          break;
        case 'error':
          lookupResults.messageType = 'danger';
          break;
        case 'success':
          lookupResults.messageType = 'success';
          break;
      }
      lookupResults.found = false;
      if (data.result_count) {
        const suggestions = [];
        data.providers.forEach((provider) => {
          let providerAddress = {};
          if (provider.addresses.length === 1)
            providerAddress = provider.addresses[0];
          else if (provider.addresses.length > 1) {
            for (let i = 0; i < provider.addresses.length; i++)
              if (provider.addresses[i]['type'] === 'MAILING')
                providerAddress = provider.addresses[i];
            if (Object.keys(providerAddress).length === 0)
              providerAddress = provider.addresses[0];
          }
          suggestions.push({
            npiNumber: provider.npi_number || '',
            firstName: provider.first_name || '',
            lastName: provider.last_name || '',
            city: providerAddress.city || '',
            state: providerAddress.state || '',
            postalCode: providerAddress.postal_code || '',
            addressType: providerAddress.type || '',
          });
        });
        if (data.result_count === 1) {
          lookupResults.provider = suggestions[0];
          lookupResults.found = true;
        }
        else
          lookupResults.suggestions = suggestions;
      }
    } catch (err) {
      this.handleError(err);
    }

    store.loading = false;
    return lookupResults;
  };

  handleError = (err) => {
    if (axios.isCancel(err)) console.log('Request aborted')
    else {
      console.log(err);
      if (err.response && err.response.status === 502) {
        console.log('ERROR 502: Connection timeout!');
      }
      else if (err.ignore403 && err.response && err.response.status === 403) {
        console.log('ERROR: Password is not correct. Please try again.');
        toast.error('Password is not correct. Please try again.', { autoClose: 7000, hideProgressBar: false });
      }
      else if (err.response && (err.response.status === 403 || (err.response && err.response.data && err.response.data.error === 'authentication failed'))) {
        console.log('ERROR 403: Forbidden! Probably authentication failed, expiring token.');
        setTimeout(this.logout, 1000);
      }
      else if (err.response && err.response.data && err.response.data.error) {
        console.log('ERROR: ', err.response.data.error);
        toast.error(err.response.data.error, { autoClose: 7000, hideProgressBar: false });
      } else if (err.response && err.response.data) {
        console.log('ERROR: ', err.response.data)
        toast.error('ERROR ' + err.response.status + '. Please refresh the page.')
      } else {
        console.log('ERROR: ', err)
        // TODO: When an unknown error occurs, try to send an error report
        toast.error('Connection Error. Please refresh the page.')
      }
    }
  };

  setPatientSurveyOptions = (oids, value) => {
    for (const oid of oids) {
      store.patientSurveyOptions[oid] = value
    }
  };

  submitPatientSurveyAnswers = async website_token => {
    store.loading = true;
    const data = {
      website_token: website_token,
      survey_answers: store.patientSurveyAnswers
    }
    try {
      await axios.post('/rest/patient/survey', data);
    } catch (error) {
      this.handleError(error);
    }
    store.loading = false;
  }

  setShowPatientSurveyModal = show => {
    store.loading = true;
    try {
      store.showPatientSurveyModal = show;
    } catch (error) {
      this.handleError(error);
    }
    store.loading = false;
  }

  getPatientConfig = async website_token => {
    store.loading = true;
    try {
      const response = await axios.get(`/rest/get_configuration/${website_token}`);
      const { data } = response;
      store.patientConfig = { ...store.patientConfig, client_url: data.contact_us_url, client_phone: data.contact_us_phone, client_name: data.customer_formatted_name }
    } catch (error) {
      store.patientConfig = { ...store.patientConfig, invalidWebsiteToken: true }
    }
    store.loading = false;
  }

  isPatientSurveyComplete = async website_token => {
    store.loading = true;
    try {
      const response = await axios.get('/rest/patient/survey', { params: { website_token } })
      store.patientConfig = { ...store.patientConfig, isPatientSurveyComplete: response.data.data['is_survey_complete'] };
    } catch (error) {
      store.patientConfig = { ...store.patientConfig, invalidWebsiteToken: true }
    }
    store.loading = false;
  }

  runSimulation = async (brands, formulary) => {
    store.simulationLoading = true;
    store.simulationResults = null;
    store.simulatedBrandPercentages = null;
    try {
      let simulationConfig = {
        brands: store.brandPrefs,
        formulary: store.formularies[store.selectedFormularyName].enabled_masks,
        masks_preferences: store.formularies[store.selectedFormularyName].masks_preferences
      }
      const response = await sendPostRequest('/rest/run_simulation', simulationConfig);
      store.simulationResults = response.data.data;
      store.simulationOptions = response.data.options;
      store.simulatedBrandPercentages = response.data.percentages;
      store.simulationCushionSizes = response.data.cushion_sizes;
      store.simulationFrameSizes = response.data.frame_sizes;
      store.simulationSizes = response.data.sizes;
      this.buildMfrPrefsChart();
    } catch (error) {
      this.handleError(error);
    }
    store.simulationLoading = false;
  }
  // get KB Videos
  getKnowledgeBaseVideoOptions = async (cancelToken = null) => {
    try {
      const url = '/rest/knowledge_base_video_options'
      const response = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.kb_video_options = response.data.data;
    } catch (error) {
      this.handleError(error);
    }
  }
  // Knowledge Base History
  getUserKnowledgeBaseHistory = async (cancelToken = null) => {
    try {
      const url = '/rest/user_knowledge_base_history';
      const response = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.user_kb_videos_completed = response.data.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  createKnowledgeBaseHistoryRecord = async data => {
    try {
      await sendPostRequest('/rest/create_knowledge_base_history_record', data);
    } catch (error) {
      this.handleError(error);
    }
  }

  getCustomerKnowledgeBaseHistory = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/customer_knowledge_base_history'
      const response = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      const knowledge_base_history = response.data.data.map(item => ({
        ...item,
        created_at: convertToCustomerTimezone(item.created_at, store?.currentSubdomain?.customerTimezone || "America/Chicago")
      }))
      store.knowledge_base_history = knowledge_base_history;
    } catch (error) {
      this.handleError(error);
    }
    store.loading = false;
  }

  // *Knowledge Base updates
  knowledgeBaseUpdateData = () => {
    store.knowledgeBaseFormData = {
      title: '',
      description: '',
      body: '',
      image_url: '',
      roles: [],
    }
    store.knowledgeBasePostDetail = null
    store.knowledgeBasePosts = []
    store.notificationInfo.knowledgeBasePostUserHistory = []
    store.notificationInfo.knowledgeBasePostsForCount = 0
  }

  resetKnowledgeBaseForm = () => {
    store.knowledgeBaseFormData = {
      title: '',
      description: '',
      body: '',
      image_url: '',
      roles: [],
    }
  }

  createKnowledgeBasePost = async data => {
    try {
      let reqUrl = '/rest/add_knowledge_base_blogpost';
      if (data?.post_id) {
        reqUrl = '/rest/edit_knowledge_base_blogpost';
      }
      return await sendPostRequest(reqUrl, data);
    } catch (error) {
      this.handleError(error);
    }
  }

  uploadKnowledgeBaseImage = async (formData) => {
    try {
      const { data } = await sendPostRequest(`/rest/kb_blog_image_upload`, formData)
      store.kb_blog_image_file = data.url
    } catch (error) {
      this.handleError(error)
    }
  }

  fetchKnowledgeBasePosts = async (isStaff = false, cancelToken = null) => {
    store.loading = true;
    try {
      const reqUrl = '/rest/get_knowledge_base_blogposts'
      const response = await sendGetRequest(reqUrl, { cancelToken: cancelToken?.token });
      const knowledgeBasePosts = response?.data?.data
      const posts = knowledgeBasePosts ? knowledgeBasePosts.map(post => ({
        ...post,
        created_at: convertToCustomerTimezone(post.created_at, store?.currentSubdomain?.customerTimezone || "America/Chicago"),
        updated_at: convertToCustomerTimezone(post.updated_at, store?.currentSubdomain?.customerTimezone || "America/Chicago")
      })) : [];
      store.knowledgeBasePosts = posts;
    } catch (error) {
      this.handleError(error);
    }
    store.loading = false;
  }

  deleteKnowledgeBasePost = async (data) => {
    store.loading = true;

    try {
      return await sendPostRequest('/rest/delete_knowledge_base_blogpost', data);
    } catch (error) {
      this.handleError(error);
    }
    store.loading = false;
  }

  getFeaturedPost = () => {
    const cloneData = [...store.knowledgeBasePosts];
    return isValidArray(cloneData)
      ? cloneData[0]
      : null;
  };

  getKnowledgeBasePostList = () => {
    const cloneData = [...store.knowledgeBasePosts];
    if (isValidArray(cloneData) && cloneData?.length > 1) {
      return cloneData.filter((_, i) => i !== 0);
    }
    return null;
  };

  createKnowledgeBasePostHistory = async (data) => {
    try {
      await sendPostRequest('/rest/create_knowledge_base_blog_history_record', data);
      store.isKbPostHistoryCalled = true;
    } catch (error) {
      this.handleError(error);
    }
  }

  toggleDetail = (data, history) => {
    history.push({
      pathname: `/help/knowledge-base/${data?.id}`
    });
    store.kbPostDetail = data;
  };

  getKnowledgeBasePostNotificationCount = () => {
    // Do not show notification count for the posts created before the user was created
    const user_created = new Date(store?.currentUser.dateJoined).getTime();
    const { knowledgeBasePostsForCount } = store.notificationInfo
    const postsAfterUserCreated = knowledgeBasePostsForCount.length ? knowledgeBasePostsForCount.filter((postDate) => {
      const post_created = new Date(postDate).getTime();
      return post_created >= user_created
    }) : []
    try {
      const notificationCount = postsAfterUserCreated.length - store.notificationInfo?.knowledgeBasePostUserHistory?.length
      return notificationCount > 0 ? notificationCount : 0
    } catch (error) {
      console.error("Error getting notification count", error);
    }
    return 0
  }

  getNotificationInfo = async (reqFor = 'all', cancelToken = null) => {
    try {
      const url = `/rest/get_notification_info?reqFor=${reqFor}`
      const { data: { results } } = await sendGetRequest(url, { cancelToken: cancelToken?.token })
      store.notificationInfo = { ...store.notificationInfo, ...results }

    } catch (error) {
      this.handleError(error)
    }
  }

  getHasUserViewedMagnetNotifications = () => {
    const hasViewedNotification = localStorage.getItem('hasViewedMagnetNotification');
    let today = new Date()
    today = today.toISOString().split('T')[0];
    return hasViewedNotification === today;
  }

  setHasUserViewedMagnetNotifications = () => {
    let today = new Date()
    today = today.toISOString().split('T')[0];
    localStorage.setItem('hasViewedMagnetNotification', today);
  }

  getHasUserViewedSurveyNotifications = () => {
    const hasViewedNotification = localStorage.getItem('hasViewedSurveyNotification');
    let today = new Date()
    today = today.toISOString().split('T')[0];
    return hasViewedNotification === today;
  }

  setHasUserViewedSurveyNotifications = () => {
    let today = new Date()
    today = today.toISOString().split('T')[0];
    localStorage.setItem('hasViewedSurveyNotification', today);
  }

  createAdHocReport = async (data) => {
    store.isAdHocLoading = true
    try {
      store.adHocApiData = data;
      const resp_stat = await sendPostRequest('/rest/adhoc_report', data);
      toast.info('In progress. Please check the Downloads section later.');
    } catch (error) {
      this.handleError(error);
    }
    store.isAdHocLoading = false
  }

  getCustomerUsernames = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/get_customer_usernames'
      const { data: { customers } } = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.customerUsernames = customers;
      return customers
    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  initiateNewLocation = () => {
    store.selectedLocationId = null;
    store.selectedLocationShortName = ""
    store.selectedLocationDBAName = store.currentSubdomain.customerFormattedName;
    store.selectedLocationResupplyFormularyName = "Recommended Resupply Optimization Formulary";
    store.selectedLocationNewPatientFormularyName = "Recommended New Patient Formulary";
    store.selectedLocationTimezone = { value: "", label: "Default (Recommended)" }
    store.selectedLocationBranchNumber = "";
    store.selectedLocationNPINumber = "";
    store.selectedLocationPhoneNumber = "";
    store.selectedLocationAddress = "";
    store.showCreateLocationModal = true;
  }

  searchNPILocations = async data => {
    store.loading = true;
    try {
      const response = await sendPostRequest('/rest/lookup/locations', data);
      store.sharedLocResults = response.data.data;
    } catch (error) {
      this.handleError(error)
    }
    store.loading = false;
  }

  getLocationsSharedWith = async (cancelToken = null) => {
    store.loading = true;
    try {
      const url = '/rest/locations_shared_with'
      const response = await sendGetRequest(url, { cancelToken: cancelToken?.token });
      store.locationsSharedWith = response.data.data;
      store.locationsSharedWithOptions = response.data.locationsSharedWithOptions;
    } catch (error) {
      this.handleError(error)
    }
    store.loading = false;
  }

  addLocationToSharedLocations = async data => {
    store.loading = true;
    try {
      const request_json = { 'action': 'create', ...data }
      let is_created = false;
      store.locationsSharedWith.forEach(loc => {
        if (loc.npi_number === data.npi_number) {
          toast.error('Location already added to Shared Locations!')
          is_created = true;
        }
      })
      if (!is_created) {
        const response = await sendPostRequest('/rest/locations_shared_with', request_json);
        store.locationsSharedWith = response.data.data;
        toast.success('Location successfully added to Shared Locations!')
      }
    } catch (error) {
      this.handleError(error)
    }
    store.loading = false;
  }

  deleteLocationFromSharedLocations = async data => {
    store.loading = true;
    try {
      const request_json = { 'action': 'delete', ...data }
      const response = await sendPostRequest('/rest/locations_shared_with', request_json);
      store.locationsSharedWith = response.data.data;
      toast.success('Location successfully deleted from Shared Locations!')
    } catch (error) {
      this.handleError(error)
    }
    store.loading = false;
  }

  favoriteSharedLocation = async data => {
    store.loading = true;
    try {
      const request_json = { 'action': 'favorite', ...data }
      const response = await sendPostRequest('/rest/locations_shared_with', request_json);
      store.locationsSharedWith = response.data.data;
      toast.success(`Location successfully ${data.is_favorite ? 'added to' : 'removed from'}  favorites!`)
    } catch (error) {
      this.handleError(error)
    }
    store.loading = false;
  }

  importPatients = async (formData) => {
    store.docUploadLoading = true;
    try {
      const response = await sendPostRequest(`/rest/patient_import_csv`, formData);
      toast.info('In progress. Please check the Downloads section for status sheet.');
    } catch (error) {
      this.handleError(error);
    }
    store.docUploadLoading = false;
  }

  importComplianceData = async (formData) => {
    store.docUploadLoading = true;
    try {
      await sendPostRequest(`/rest/compliance_data_import_csv`, formData);
      toast.success('Document Successfully Uploaded!');
    } catch (error) {
      this.handleError(error);
    }
    store.docUploadLoading = false;
  }

  emailImporterReport = async () => {
    store.docUploadLoading = true;
    try {
      const resp = await sendPostRequest(`/rest/email_patient_import_csv`, {});
      toast.success('Report emailed Successfully!');
    } catch (error) {
      this.handleError(error);
    }
    store.docUploadLoading = false;
  }

  downloadImporterTemplate = () => {
    try {
      let url = `/rest/patient_importer_template?token=${readCookieValue('token')}`
      return url
    } catch (error) {
      this.handleError(error);
    }
  }

  resetAnAlertFilter = (name) => {
    const arrayValue = ["resolvedBy", "createdBy", "locations", "alertType", "assignedTo"];
    if (arrayValue.includes(name)) {
      store.alertFilters[name] = [];
    } else if (name = "status") {
      store.alertFilters[name] = null;
    } else {
      store.alertFilters[name] = '';
    }
  }

  resetAlertFilters = () => {
    store.alertFilters = {
      patientId: '',
      status: null,
      resolvedBy: [],
      locations: [],
      alertType: [],
      createdBy: [],
      assignedTo: [],
      startDate: '',
      endDate: '',
    }
  }

  setSelectedPatientAlerts = (alerts) => {
    store.patientAlerts = alerts
  }

  loadPatientAlerts = async (website_token) => {
    store.patientAlertsLoading = true
    try {
      var url = `/rest/alerts/${website_token}`
      var { data } = await sendGetRequest(url);
      if (data.alerts) {
        const { alerts } = data
        this.setSelectedPatientAlerts(alerts);
        store.isPtAlertsCalled = true
      }
    } catch (err) {
      console.log(err)
    }
    store.patientAlertsLoading = false
  }

  loadAlerts = async (fromAlertsPage, cancelToken = null) => {
    if (!fromAlertsPage) {
      store.loading = true;
    }
    store.alertsLoading = true;
    try {
      var url = "/rest/patient_alerts"
      url += '?max_count=50'
      url += '&first_row_num=' + 50 * (store.alertsPageNumber - 1);
      if (store.alertFilters.locations.length > 0) {
        url += '&locations=' + store.alertFilters.locations.join(';');
      }
      if (store.alertFilters.patientId) {
        url += '&patient_id=' + store.alertFilters.patientId;
      }
      if (store.alertFilters.startDate) {
        url += '&start_date=' + formatDateToString(store.alertFilters.startDate);
      }
      if (store.alertFilters.endDate) {
        url += '&end_date=' + formatDateToString(store.alertFilters.endDate);
      }
      if (store.alertFilters.resolvedBy.length > 0) {
        url += '&resolved_by=' + store.alertFilters.resolvedBy.join(';');
      }
      if (store.alertFilters.createdBy.length > 0) {
        url += '&created_by=' + store.alertFilters.createdBy.join(';');
      }
      if (store.alertFilters.assignedTo.length > 0) {
        url += '&assigned_to=' + store.alertFilters.assignedTo.join(';');
      }
      if (store.alertFilters.alertType.length > 0) {
        url += '&alert_type=' + store.alertFilters.alertType.join(';');
      }
      if (store.alertFilters.status != null && store.alertFilters.status.length > 0) {
        url += '&alert_status=' + store.alertFilters.status.join(';');
      }
      const { data } = await sendGetRequest(url, { cancelToken: cancelToken?.token })
      if (data.alerts) {
        const { alerts } = data
        this.setAlerts(alerts, data.total_alerts);

        // Update alerts count based on already existing history records
        // Only if page is loaded
        if (fromAlertsPage === true && store.notificationInfo.unreadAlerts > 0) {
          await this.markAlertsRead();
        }
      }

      if (store.settings.patientAlertsEnabled) this.getNotificationInfo('alerts')
    } catch (err) {
      console.log(err)
    }
    store.loading = false;
    store.alertsLoading = false;
  }

  setAlerts = (alerts, count) => {
    store.alerts = alerts.map((alrt) => ({ ...alrt, expend: false }));
    store.alertsTotalRows = count;
  }

  updatePatientAlerts = (newAlert) => {
    const index = store.patientAlerts.findIndex((alrt) => alrt.alert_id === newAlert.alert_id)
    if (index >= 0) {
      const newAlerts = update(store.patientAlerts, { $splice: [[index, 1, newAlert]] });
      store.patientAlerts = newAlerts;
    }
  }

  updateAlert = (newAlert) => {
    const index = store.alerts.findIndex((alrt) => alrt.alert_id === newAlert.alert_id)
    if (index >= 0) {
      const newAlerts = update(store.alerts, { $splice: [[index, 1, newAlert]] });
      store.alerts = newAlerts;
    }
  }

  resolveAlert = async (data, forPatient = false) => {
    try {
      var url = "/rest/patient_alert"
      var { data } = await sendPutRequest(url, data);
      if (data.alert) {
        if (forPatient) {
          this.updatePatientAlerts(data.alert)
        } else {
          this.updateAlert(data.alert);
        }
      }
    } catch (err) {
      toast.error(err.response.data.error)
    }
  }

  expendAlertRow = (row) => {
    this.updateAlert({ ...row, expend: !row.expend })
  }

  markAlertsRead = async () => {
    try {
      await sendPostRequest('/rest/create_alert_history_records');
    } catch (err) {
      this.handleError(err)
    }

  }

  ptOriginDifferentAndSharedWithMe = () => {
    const { shared_with, location_npi_number } = store?.selectedRow;
    const sharedWithNpis = shared_with && shared_with.map(obj => obj.value)
    const currentUserLocNpis = store.currentSubdomain.locationDetails?.map(loc => loc.npi_number)

    // Check 1. If patient shared to me. 2. If patient doesn't belong to me.
    return sharedWithNpis && sharedWithNpis.some(npi => currentUserLocNpis?.includes(npi)) &&
      !currentUserLocNpis?.includes(location_npi_number)
  }

  toggleReplyModal = (parentNote = null) => {
    store.followupModal = !store.followupModal;
    store.parentNote = parentNote
  }

  updateSharedNotes = (updatedNote) => {
    const indx = store.selectedRow.shared_notes.findIndex((note) => note.note_id === updatedNote.note_id)
    if (indx >= 0) {
      const oldNote = store.selectedRow.shared_notes[indx]
      const newNotes = { ...updatedNote, shared_with_npis: [...oldNote.shared_with_npis] }

      const newSharedNotes = update(store.selectedRow.shared_notes, { $splice: [[indx, 1, newNotes]] })
      store.selectedRow.shared_notes = newSharedNotes
    }
  }

  loadPatientMessages = async () => {
    store.ptMessagesLoading = true
    try {
      let url = '/rest/patient_messages'
      url += '?max_count=50'
      url += '&first_row_num=' + 50 * (store.patientMessagesPageNum - 1);
      if (store.patientMessagesFilters.locations.length > 0) {
        url += '&locations=' + store.patientMessagesFilters.locations.join(';');
      }
      if (store.patientMessagesFilters.patientId) {
        url += '&patient_id=' + store.patientMessagesFilters.patientId;
      }
      if (store.patientMessagesFilters.startDate) {
        url += '&start_date=' + formatDateToString(store.patientMessagesFilters.startDate);
      }
      if (store.patientMessagesFilters.endDate) {
        url += '&end_date=' + formatDateToString(store.patientMessagesFilters.endDate);
      }
      if (store.patientMessagesFilters.createdBy.length > 0) {
        url += '&created_by=' + store.patientMessagesFilters.createdBy.join(';');
      }
      if (store.patientMessagesFilters.messageType.length > 0) {
        url += '&message_type=' + store.patientMessagesFilters.messageType.join(';');
      }
      if (store.patientMessagesFilters.messageStatus.length > 0) {
        url += '&message_status=' + store.patientMessagesFilters.messageStatus.join(';');
      }

      const { data } = await sendGetRequest(url)
      // Add isUnread = true if thread includes any unread messages
      data.patient_messages.forEach(message => {
        const { websiteTokensWithUnreadMessages } = store.notificationInfo
        message.isUnread = websiteTokensWithUnreadMessages.includes(message.website_token);
      });
      store.allPatientMessages = data.patient_messages;
      store.patientMessagesTotalRows = data.total_patient_messages;
    } catch (error) {
      this.handleError(error)
    }
    store.ptMessagesLoading = false
  }

  getPtMessagingSetting = async () => {
    store.loading = true
    try {
      const { data } = await sendGetRequest(`/rest/pt_messaging_setting?subdomain=${window.location.host.split('.')[0]}`)
      store.isPtMessagingEnabled = data.patientMessagingEnabled
    } catch (error) {
      this.handleError(error)
    }
    store.loading = false
  }

  resetPatientMessagesFilters = () => {
    store.patientMessagesFilters = {
      patientId: '',
      locations: [],
      startDate: '',
      endDate: '',
      messageType: [],
      messageStatus: [],
      createdBy: [],
      resolvedBy: [],
    }
  }
  resetAPatientMessageFilter = (name) => {
    const arrayValue = ["locations", "messageType", "messageStatus", "createdBy", "resolvedBy"];
    if (arrayValue.includes(name)) store.patientMessagesFilters[name] = [];
    else store.patientMessagesFilters[name] = '';
  }

  getPatientMessagesFromThread = async (website_token, isFromClinician = false) => {
    store.ptMessagesFromThreadLoading = true
    // If from clinician, then apply set is_read = true (in backend) for all unread messages
    try {
      const { data } = await sendGetRequest(`/rest/patient_messages_thread?website_token=${website_token}&isFromClinician=${isFromClinician}`)
      store.patientMessages = data.messages

      // Mark unread thread as read
      const idx = store.allPatientMessages.findIndex(msg => msg.website_token === website_token);
      let isUnread
      if (idx > -1) {
        if (store.allPatientMessages[idx].isUnread === true) {
          isUnread = true
          store.allPatientMessages[idx].isUnread = false;
        }
      }
      // Get the updated notif count after viewing a msg thread
      if (store.notificationInfo.unreadPtMessagesCount > 0 && isUnread) {
        this.getNotificationInfo('patientMessages')
      }
    } catch (err) {
      this.handleError(err)
    }
    store.ptMessagesFromThreadLoading = false
  }

  uploadPatientMessageFile = async (formData, website_token) => {
    try {
      const { data } = await sendPostRequest(`/rest/patient_messages/file_upload/${website_token}`, formData)
      store.pt_msg_file = data.url
    } catch (err) {
      this.handleError(err)
    }
  }

  validatePatientMessage = async (payload) => {
    try {
      const { data } = await sendPostRequest('/rest/patient_messages_validation', payload);
      store.isPatientValidated = data.isValid;
      store.chatPatientName = data.patientName;
      store.chatPatientTimezone = data.patientTimezone;
    } catch (err) {
      this.handleError(err)
    }
  }

  updatePatientMessages = (newMsg) => {
    store.patientMessages.push(newMsg);
  }

  resolvePatientMessages = async (payload) => {
    const { data } = await sendPostRequest(`/rest/patient_messages_thread`, payload)
    store.patientMessages = data.messages
  }

  updateCurrentUserPreferences = (preferences) => {
    const newData = update(store.currentUser, { userPreferences: { $set: preferences } })
    store.currentUser = newData
  }

  setMyPreferences = async (preference) => {
    try {
      const url = `/rest/user_preferences`
      const { data } = await sendPostRequest(url, preference)
      if (data.isUpdated) {
        this.updateCurrentUserPreferences(data.preferences)
      } else {
        toast.error("Error while setting Notif Preferences.")
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  setMyHistory = (history) => {
    store.myActivities.alerts = history.alerts
  }

  getMyActivities = async () => {
    try {
      const url = `/rest/user_history`
      const { data } = await sendGetRequest(url)
      this.setMyHistory(data)
    } catch (error) {
      this.handleError(error)
    }
  }

  editMyProfile = async (newProfile) => {
    try {
      const url = `/rest/user_edit_my_profile`
      const { data } = await sendPostRequest(url, newProfile)
      if (data.isUpdated) {
        this.initializeCurrentUser()
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  // This is called for each row in alerts, docs, etc. 
  // TODO Fix it by calling it once per page using componentDidMount
  setAssigningOptions = () => {
    let options = []
    if (store.users && store.users.length > 0) {
      for (let user of store.users) {
        // Remove any non-active users first
        if (!user.isActive) continue;

        // For the logged-in user, check if they can assign this user based on role
        const admins = ['super_administrator', 'administrator']
        let isAuthorize = admins.includes(store.currentUser.userRole)
        if (store.currentUser.userRole === 'manager' && !(admins.includes(user.role))) {
          isAuthorize = true
        }

        if (store.currentUser.username === user.username) options.push({ label: user.username, value: user.username, name: 'assignedTo' });
        else options.push({ label: user.username, value: user.username, name: 'assignedTo', isDisabled: !isAuthorize });
      }
    }
    else {
      options = [{ label: store.currentUser.username, value: store.currentUser.username, name: 'assignedTo' }]
    }
    return options
  }

  setProfileEdit = (newValue) => {
    store.profileEdit = newValue;
  }

  // * check if device is mobile or tablet
  handleResize = (min, max) => {
    if (window.innerWidth < max && window.innerWidth > min) store.isTablet = true;
    else store.isTablet = false;
    if (window.innerWidth < min) store.isMobile = true;
    else store.isMobile = false;
  }

  notAuthorizedForSettingPage = () => {
    return (Object.keys(store.currentSubdomain)?.length && (store.currentSubdomain?.locations?.length == 0 || store.currentSubdomain.isRegion || store.currentSubdomain.internal_client)) || (Object.keys(store.currentUser).length && !store.currentUser.canEditSettings)
  }

  getAllParentCustomers = async () => {
    store.loading = true;
    try {
      const { data } = await sendGetRequest('/rest/all_parent_customers')
      store.allParentCustomers = data.allParentCustomers
    } catch (err) {
      this.handleError(err)
    }
  }

  getAllCustomers = async () => {
    try {
      const { data: { allCustomers } } = await sendGetRequest('/rest/get_all_customers')
      store.allCustomers = allCustomers
    } catch (error) {
      this.handleError(error)
    }
  }

  getAllMfrs = async () => {
    try {
      const { data: { allMfrs } } = await sendGetRequest('/rest/get_all_mfrs')
      store.allMfrs = allMfrs
    } catch (error) {
      this.handleError(error)
    }
  }

  onboardTradeShowPt = async (patientData) => {
    store.onboardPatientLoading = true
    try {
      store.onboardPatientURL = ''
      const response = await sendPostRequest('/rest/scan/tradeshow_mode', patientData)
      store.onboardPatientSuccess = true
      store.onboardPatientURL = response.data.url
    } catch (error) {
      this.handleError(error)
    }
    store.onboardPatientLoading = false
  }

  onboardPatient = async (patientData) => {
    store.onboardPatientLoading = true
    try {
      store.onboardPatientURL = ''
      const response = await sendPostRequest('/rest/scan/onboard_patient', patientData)
      store.onboardPatientSuccess = true
      store.onboardPatientURL = response.data.url
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        console.log('ERROR onboarding pt: ', err.response.data.error);
        if (err.response.data.existingPtError) {
          store.existingPtError = true
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          toast.error(err.response.data.error);
        }
      } else {
        console.log('ERROR onboarding pt: ', err)
        toast.error('Error!')
      }
    }
    store.onboardPatientLoading = false
  }

  verifyRecaptcha = async (reCaptcha) => {
    store.verifyRecaptchaLoading = true
    try {
      const { data: { data } } = await axios.post('/rest/verify_recaptcha', { reCaptcha })
      store.verifyRecaptchaLoading = false
      if (data) return JSON.parse(data)
    } catch (err) {
      console.log(err)
      this.handleError(err)
    }
    store.verifyRecaptchaLoading = false
  }

  getCurrentSubdomainName = async () => {
    try {
      const { data } = await sendGetRequest('/rest/get_current_subdomain_name')
      return data
    } catch (error) {
      this.handleError(error)
    }
  }

  postFrontEndError = async (error, errorComponentStack) => {
    try {
      await sendPostRequest('/rest/log_frontend_errors', {
        error_message: 'FRONT END ERROR:' + error, error_component_stack: errorComponentStack
      })
    } catch (error) {
      this.handleError(error)
    }
  }

  bulkReinvites = async () => {
    // Bulk re-invites. Only allowed for staff users
    const list_url = this.buildPatientListUrl(false, false);
    const url = list_url.replace("/patient_list/", "/reinvite_filtered_patients/");
    store.bulkReinvitesLoading = true;
    try {
      const { data } = await sendPostRequest(url)
      toast.success(`${data.reinvited} Patients re-invited`)
    } catch (error) {
      this.handleError(error)
    }
    store.bulkReinvitesLoading = false;
  }

  sendSystemNotification = async (notificationDetails) => {
    //send post request to 'rest/system_notification'
    try {
      await sendPostRequest('/rest/system_notification', notificationDetails);
      toast.success('Notification sent');
    }
    catch (error) {
      toast.error('error setting system notification');
    }
  }

  handleSystemNotification = (systemNotification) => {
    if (systemNotification) {
      // Store system notification
      localStorage.setItem('systemNotification', JSON.stringify(systemNotification));
      // Manually trigger the storage event
      const storageChangeEvent = new Event('storage');
      window.dispatchEvent(storageChangeEvent);
    }
  }

  setShowInPersonModal = show => {
    store.showInPersonModal = show;
    if (!show) {
      store.inPersonModePatient = {}
    }
  }

  gotoInPersonScan = () => {
    const { websiteToken, inviteUrl } = store.inPersonModePatient
    this.setShowInPersonModal(false);
    if (inviteUrl) {
      const msg = `Redirecting pt: ${websiteToken} to inperson scan`
      console.log(msg)
      window.open(inviteUrl);
    }
  }

  reInviteUnsubscribed = (channel = "email") => {
    // Reached by Users who create or are assignedTo an unsubscribed patient
    // Resets and Re-invites a patient by alternative delivery method
    const inviteByEmail = channel === 'email'
    const should_send = inviteByEmail ? 'should_send_email' : 'should_send_sms'
    store.loading = true;
    try {
      this.editData({
        should_send_sms: !inviteByEmail,
        should_send_email: inviteByEmail,
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
      },
        should_send,
        should_send
      );
      this.reInvite();
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  }

  authorizedForAdhocPage = () => {
    return store.settings?.adHocEnabled &&
      (store.settings?.adHocAllowedRoles.includes(store.currentUser?.userRole)) || store.currentUser?.isStaff
  }

  isMicrosoftLoginEnabled = async () => {
    try {
      const { data } = await axios.get(`/rest/is_microsoft_login_enabled?subdomain=${subdomain}`)
      return data.isMicrosoftLoginEnabled
    } catch (error) {
      this.handleError(error)
    }
  }

  getSelectedBranchPreferences = async (ptAssignedSubdomain) => {
    store.loading = true;
    try {
      let url = 'rest/branch_preferences/' + ptAssignedSubdomain;
      const resp = await sendGetRequest(url);
      store.formularies = { ...store.formularies, ...resp.data.formularies };
      // Initialize the drop-down in Settings->Formulary
      if (!store.selectedFormularyName && Object.keys(store.formularies).length > 0) {
        store.selectedFormularyName = Object.keys(this.getActiveFormularies())[0];
      }
      store.formularyNameOpts = this.getFormularyNameOptions()
      store.currentFormulary = store.formularies[store.selectedFormularyName].masks_preferences;
      store.selectedBranchPreferences = resp.data.branch_preferences

    } catch (err) {
      this.handleError(err);
    }
    store.loading = false;
  }

  submitNonRecommendedFeedback = async (refitAnswers, refitVersion) => {
    store.loading = true;
    try {
      refitAnswers['switch_reason'] = 'non-recommended-opt';
      let data = {
        patient_subdomain: store.selectedRow.subdomain,
        website_token: store.selectedRow.website_token,
        refit_details: {
          version: refitVersion,
          answers: refitAnswers
        }
      };
      await sendPostRequest('/rest/refitfeedback', data);
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  };

  createNewCustomer = async (customerData, resetForm) => {
    try {
      await sendPostRequest('/rest/create_customer', customerData)
      toast.success('Customer created successfully!')
      resetForm()
    } catch (error) {
      this.handleError(error)
    }
  }

  getAvailableCountriesForTwilioNumber = async () => {
    try {
      const { data: { countries } } = await sendGetRequest('/rest/get_available_countries_for_twilio_number')
      return countries
    } catch (error) {
      this.handleError(error)
    }
  }

  getTwilioAvailablePhoneNumbers = async (params) => {
    try {
      const { data: { phoneNumbers } } = await sendGetRequest('/rest/get_twilio_available_phone_numbers', { params })
      return phoneNumbers
    } catch (error) {
      this.handleError(error)
    }
  }

  buyTwilioNumber = async (params) => {
    store.buyTwilioNumberLoading = true
    try {
      const { data: { data } } = await sendPostRequest('/rest/buy_twilio_number', params)
      store.buyTwilioNumberLoading = false
      return data
    } catch (error) {
      this.handleError(error)
      store.buyTwilioNumberLoading = false
    }
  }

  authorizedForAdmin = () => {
    return store.currentSubdomain?.customerSubdomain === 'admin' && store.currentUser?.isStaff
  }

  loadClientsBilling = async (billingPageNumber = 0) => {
    store.loading = true;
    let url = '/rest/customer_billing?max_count=15';
    url += `&first_row_num=${10 * (billingPageNumber)}`
    try {
      const { data } = await sendGetRequest(url)
      store.billingPageNumber = billingPageNumber;
      store.clientsBilling = data.clientsBilling;
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  }

  loadCustomerBilling = async (client) => {
    store.loading = true;
    let url = '/rest/customer_billing';
    url += `?client=${client}`
    try {
      const { data } = await sendGetRequest(url)
      // Update customer billing info
      store.selectedBillingClient = data.clientsBilling[0]
      store.clientsBilling = store.clientsBilling.map(client =>
        client.customer_username === data.clientsBilling[0].customer_username ? data.clientsBilling[0] : client
      )
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;
  }

  isBillingLoaded = () => {
    return !!store.clientsBilling
  }

  updateCustomerBilling = async (data) => {
    store.loading = true;
    try {
      await sendPostRequest('/rest/update_customer_billing', data);
      toast.success('client updated');
      this.loadCustomerBilling(data?.client)
    } catch (err) {
      this.handleError(err)
    }
    store.loading = false;

  }

  downloadBillingCSV = (client, summary = false) => {
    var url = '/rest/customer_billing_file?token=' + readCookieValue('token');
    if (client) {
      url += '&client=' + client;
    }
    url += '&summary=' + summary
    return url;
  }

  downloadBillingPDF = (client) => {
    let url = '/rest/customer_billing_file?token=' + readCookieValue('token');
    if (client) {
      url += '&client=' + client;
      url += '&pdf=true';
    }
    return url;
  }

  loadDownloads = async () => {
    store.downloadsLoading = true;
    try {
      let url = '/rest/downloads?max_count=50'
      url += '&first_row_num=' + 50 * (store.downloadsPageNumber - 1);
      const { data } = await sendGetRequest(url);
      store.downloads = data.downloads;
      store.totalDownloads = data.total_downloads;
    } catch (err) {
      this.handleError(err)
    }
    store.downloadsLoading = false;
  }

  downloadPatientCSV = async () => {
    try {
      const response = await sendGetRequest(encodeURI(this.buildPatientListUrl(false, true)));
      toast.info('In progress. Please check the Downloads section later.');
    } catch (error) {
      this.handleError(error);
    }
  }

  getFetchDowloadLink = (downloadId) => {
    try {
      return `/rest/fetch_download?id=${downloadId}&token=${readCookieValue('token')}`;
    } catch (error) {
      this.handleError(error)
    }
  }
}

export let appStore = new stores();
